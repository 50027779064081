import ApiService from "../../api/api.js";
import {getTranslateMessageAPI} from "../../utils/helpers";
import {cuttingWindowHtml, labelsWindowHtml, mergeWindowHtml, packagingWindowHtml} from "../../printHtml";
import {CuttingHtml} from "../../printHtml/cuttingHtml";
import Labels from "../../printHtml/Labels";
import ApiGiblab from "../../api/ApiGiblab";
import {gaEvents} from "../../gaEvents";
import {PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES} from "../../components/Modals/PackagingPrintingModal/Form";
import {PackagingHtml} from "../../printHtml/packagingHtml";
import {
    MAIN_ORDER_DETAIL_WHOLESALE_GOODS,
    MAIN_ORDER_DETAIL_WHOLESALE_INFORMATION_BY_GOODS_ID,
    MAIN_SHEET_DATA_PRINT_CONFIRM_MODAL
} from "../actions/MainActions";
import {wsGetGoodsInformationByMaterialsIdsThunk} from "../thunks/wholesale/orders_goods/ws_order_goods_thunk";
import {getAllAdvancedGoodsServiceByOrderIdThunk} from "../thunks/advansed-goods-service-thunk";
import {getGeneralCurrenciesThunk} from "../thunks/salary_project/general_thunk";

const apiService = new ApiService();
const FETCHING_ORDERS_STATUS = "FETCHING_ORDER_STATUS"

const CONFIRM_MODAL_TOGGLE = "MODAL/CONFIRM_TOGGLE";
const TOGGLE_SIDBAR_VIEW = "TOGGLE_SIDBAR_VIEW";
const TOGGLE_MENU_ITEM_OPEN = "TOGGLE_MENU_ITEM_OPEN";
const TOGGLE_LANG_MENU = "TOGGLE_LANG_MENU";
const SET_ACTUAL_ORDERS_DATA = "SET_ACTUAL_ORDERS_DATA";
const SET_ARCHIVE_ORDERS_DATA = "SET_ARCHIVE_ORDERS_DATA";
const TOGGLE_LOADER_STATUS = "TOGGLE_LOADER_STATUS";
const SET_ORDER_DETAIL_DATA = "SET_ORDER_DETAIL_DATA";
const CHANGE_CLIENT_FORM_DATA = "CHANGE_CLIENT_FORM_DATA";
const SUBMIT_CLIENTS_FORM = "SUBMIT_CLIENTS_FORM";
const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";
const CHANGE_ORDERS_FILTER_FORM_DATA = "CHANGE_ORDERS_FILTER_FORM_DATA";
const SET_CLIENTS_DATA = "SET_CLIENTS_DATA";
const SET_CLIENT_DETAIL_DATA = "SET_CLIENT_DETAIL_DATA";
const TOGGLE_ORDER_CHANGE_STATUS = "TOGGLE_ORDER_CHANGE_STATUS";
const SET_PRODUCTION_TASK_ACTIVE_TAB = "SET_PRODUCTION_TASK_ACTIVE_TAB";
const SET_PRODUCTION_TASK_ORDERS_FOR = "SET_PRODUCTION_TASK_ORDERS_FOR";
const SET_PRODUCTION_TASK_ORDERS_IN = "SET_PRODUCTION_TASK_ORDERS_IN";
const SET_PRODUCTION_TASK_ORDERS_COMPLETE =
    "SET_PRODUCTION_TASK_ORDERS_COMPLETE";
const SET_PRODUCTION_TASK_ORDERS_FOR_SHIPPING =
    "SET_PRODUCTION_TASK_ORDERS_FOR_SHIPPING";
const SET_CLIENT_DETAIL_ORDERS_DATA = "SET_CLIENT_DETAIL_ORDERS_DATA";
const TOOGLE_CLIENT_DETAIL_EDIT = "TOOGLE_CLIENT_DETAIL_EDIT";
const CHANGE_CLIENT_DETAIL_DATA = "CHANGE_CLIENT_DETAIL_DATA";
const TOGGLE_ORDER_DETAIL_DISCOUNT_EDIT = "TOGGLE_ORDER_DETAIL_DISCOUNT_EDIT";
const SET_ORDER_DETAIL_DICSOUNTS_DATA = "SET_ORDER_DETAIL_DICSOUNTS_DATA";
const SET_ORDER_DETAIL_MATERIAL_DISCOUNT = "SET_ORDER_DETAIL_MATERIAL_DISCOUNT";
const SET_ORDER_DETAIL_SERVICE_DISCOUNT = "SET_ORDER_DETAIL_SERVICE_DISCOUNT";
const CHANGE_ORDERS_ADD_FORM_VALUE = "CHANGE_ORDERS_ADD_FORM_VALUE";
const SUBMIT_ADD_ORDER_FORM = "SUBMIT_ADD_ORDER_FORM";
const TOGGLE_ORDER_DETAIL_FORM_EDIT = "TOGGLE_ORDER_DETAIL_FORM_EDIT";
const CHANGE_ORDER_DETAIL_DATA_VALUE = "CHANGE_ORDER_DETAIL_DATA_VALUE";
const CHANGE_ORDER_DETAIL_MATERIAL_CALC_TYPES =
    "CHANGE_ORDER_DETAIL_MATERIAL_CALC_TYPES";
const SET_REPORTS_DATA_SALE = "SET_REPORTS_DATA_SALE";
const TOGGLE_REPORTS_DATA_SALE_ACTIVE = "TOGGLE_REPORTS_DATA_SALE_ACTIVE";
const CHANGE_REPORTS_SALE_FORM_VALUE = "CHANGE_REPORTS_SALE_FORM_VALUE";
const SET_CURRENCIES_DATA = "SET_CURRENCIES_DATA";
const SET_FIRM_DATA_MANAGERS = "SET_FIRM_DATA_MANAGERS";
const TOGGLE_NOTIFY_OPEN_STATUS = "TOGGLE_NOTIFY_OPEN_STATUS";
const SET_NOTIFY_DATA = "SET_NOTIFY_DATA";
const TOGGLE_MODAL_SET_PROBLEM = "TOGGLE_MODAL_SET_PROBLEM";
const TOGGLE_MODAL_VIEW_PROGRAMM = "TOGGLE_MODAL_VIEW_PROGRAMM";
const CAHNGE_SET_PROBLEM_MODAL_REASON = "CAHNGE_SET_PROBLEM_MODAL_REASON";
const CHANGE_PRINT_ALL_DOCUMENTS_DATA = "CHANGE_PRINT_ALL_DOCUMENTS_DATA";
const ORDERS_PRODUCTS_COUNTS_DATA = "ORDERS_PRODUCTS_COUNTS_DATA";
const SET_CHEXBOX = "SET_CHEXBOX";
const SET_MATERIALS = "SET_MATERIALS";
const SET_SERVICE = "SET_SERVICE";
const RESET_FILTERS = "RESET_FILTERS";
const SET_FIRM_DATA_USERS = "SET_FIRM_DATA_USERS";
const SET_STATISICS = "SET_STATISICS";
const SET_STATISICS_SINGLE = "SET_STATISICS_SINGLE";
const SET_PRODUCTION_TASK_TYPES = "SET_PRODUCTION_TASK_TYPES";
const SET_PODUCTION_TASK_DETAIL_DATA = "SET_PODUCTION_TASK_DETAIL_DATA";
const SET_PRODUCTION_TASK_BY_MATERIAL = "SET_PRODUCTION_TASK_BY_MATERIAL";
const TOGGLE_PRODUCTIONTASK_CHANGE_STATUS =
    "TOGGLE_PRODUCTIONTASK_CHANGE_STATUS";
const CHANGE_PRODUCTIONTASKS_FILTER_FORM_DATA =
    "CHANGE_PRODUCTIONTASKS_FILTER_FORM_DATA";
const CHANGE_DASHBOARD_FILTER_FORM_DATA = "CHANGE_DASHBOARD_FILTER_FORM_DATA";
const CHANGE_DASHBOARD_FILTER_FORM_DATA_SINGLE =
    "CHANGE_DASHBOARD_FILTER_FORM_DATA_SINGLE";
const TOGGLE = "TOGGLE";
const SET_TOGGLE = "SET_TOGGLE";
const TOGGLE_SELECT_BY_MATERIAL = "TOGGLE_SELECT_BY_MATERIAL";
const TOGGLE_SELECT_BY_MATERIAL_GROUP = "TOGGLE_SELECT_BY_MATERIAL_GROUP";
const UPDATE_SETTINGS = "UPDATE_SETTINGS";

const SET_TERMS_DASHBOARD = "SET_TERMS_DASHBOARD";
const SET_NEW_CLIENTS_DASHBOARD = "SET_NEW_CLIENTS_DASHBOARD";
const SET_SETTINGS_1C_DATA = "SET_SETTINGS_1C_DATA";
const SET_SETTINGS_PROJECT_DATA = "SET_SETTINGS_PROJECT_DATA";

const SET_LEFTOVERS_ORDER_ADD_FROM = "SET_LEFTOVERS_ORDER_ADD_FROM";
const SET_ACCESS_TO_CREATE_ORDER_ADD_FROM = "SET_ACCESS_TO_CREATE_ORDER_ADD_FROM";
const CLEAR_ACTUAL_FORM_SEARCH = "CLEAR_ACTUAL_FORM_SEARCH";

const FILE_MANAGER_MODAL_DT = "FILE_MANAGER_MODAL_DT";
const SET_PAYMOUNT_STATUS = "SET_PAYMOUNT_STATUS";
const DELETE_ORDER_ID = 'DELETE_ORDER_ID'

const PROJECT_PRODUCTION_ALL_MATERIALS = 'PROJECT_PRODUCTION/ALL_MATERIALS';
const PROJECT_PRODUCTION_SET_MATERIAL_ORDER = 'PROJECT_PRODUCTION/SET_MATERIAL_ORDER';
const UPDATE_COMMENT_OF_ORDER = 'ORDER_DETAIL/UPDATE_COMMENT_OF_ORDER';

const PROJECT_TASK_ALL_COUNTS_ORDERS = 'PROJECT_TASK/ALL_COUNTS_ORDERS'
const NEWS_SET_LIST = "NEWS/SET_LIST"

const NEWS_INITIAL_VALUES = {
    page: 0,
    pages: 0,
    news: []
}

const d = new Date();
const curr_date = d.getDate();
const curr_month =
    String(d.getMonth() + 1).length === 1
        ? "0" + (d.getMonth() + 1)
        : d.getMonth() + 1;
const curr_year = d.getFullYear();
const currentDate = curr_year + "-" + curr_month + "-" + curr_date;
const nextDate = curr_year + "-" + curr_month + "-" + (curr_date + 1);
const manager = "";


const INITIAL_ORDER_FORM = {
    code1c: "",
    code: "",
    id: "",
    name: "",
    date: "",
    status: "actual",
    client: "",
    date_production: "",
    manager: "",
    limit: 1,
    error1c: "",
    production_stage_number: "",
    summ_order: "",
    material_type: "",
    service_type: "",
    order_out_of_date: "",
    collected: "",
    materials: [],
    services: [],
}

let initialState = {
    confirm_modal_common: {
        isOpen: false,
        message: null, onConfirm: null
    },
    productionTaskCounts: {
        "for": {
            count: 0,
            count_all: 0,
        },
        "in": {
            count: 0,
            count_all: 0,
        },
        "complete": {
            count: 0,
            count_all: 0,
        },
        "forShipping": {
            count: 0,
            count_all: 0,
        },
    },
    sidebarCollapse: true,
    menu: {
        active: "dashboard"
    },
    lang: {
        active: "us",
        isOpen: false,
        languages: [
            {
                code: "us",
                name: "English"
            },
            {
                code: "ru",
                name: "Русский"
            },
            {
                code: "ua",
                name: "Українська"
            },
            {
                code: "pl",
                name: "Polska"
            }
        ]
    },
    orders: {
        actual: []
        // archive: []
    },
    fetchingOrders: {
        isPending: true,
        isResolved: false
    },
    productionTasks: {},
    settings: [
        {id: 1, check: true, title: "Показывать информацию по сырью"},
        {id: 2, check: true, title: "Показывать информацию по паллетам"},
        {id: 3, check: true, title: "Показывать информацию по материалам и услугам"},
        {id: 4, check: true, title: "Показывать информацию по производственным заданиям"}
    ],

    order_detail: {},
    order_detail_wholesale_goods_information: null,
    order_detail_wholesale_goods: {
        exist: null, need: null
    },
    sheet_data_print_confirm_modal: {
        isOpen: false,
        full: false,
        order: null,
    },
    production_task_detail: {},
    productionTasksMaterials: [],
    projectProductionAllMaterials: {},
    orderDetailEdit: false,
    ordersForm: INITIAL_ORDER_FORM,
    dashboardForm: {
        status: "1",
        date_prev: "0",
        day_num: "",
        manager: ""
    },
    dashboardFormSingle: {
        status: "1",
        date_prev: "0",
        day_num: "",
        manager: localStorage.getItem("i_furn_user_id")
    },
    productionTasksForm: {
        id: "",
        date_production: "",
        status: "",
        date: ""
    },

    orderDiscountActive: null,
    orderDiscountsEdit: false,
    statistics: {},
    single: {},
    productionTasksTypes: [],
    orderDiscounts: {
        goods: [],
        materials: [],
        services: []
    },
    dashboardStatus: [
        {id: 1, name: "Подтверждены"},
        {id: 2, name: "Отгружены"},
        {id: 3, name: "Подтверждены покупателем"},
        {id: 4, name: "Подтверждены в производство"},
        {id: 5, name: "Взяты в работу"},
        {id: 6, name: "Произведены"},
        {id: 7, name: "Отменены"},
        {id: 8, name: "Всего заказов"}
    ],
    dashboardDate: [
        {
            id: 1,
            name: "Текущий месяц",
            val: "0"
        },
        {
            id: 2,
            name: "Прошлый месяц",
            val: "1"
        }
        // {
        //   id: 3,
        //   name: "За день",
        //   val: "0"
        // }

    ],

    orderStatus: [
        {
            id: 1,
            name: "в редактировании",
            color: "primary",
            roles: [9, 3]
        },
        {
            id: 2,
            name: "подтвержден",
            color: "success",
            roles: [9, 3]
        },
        {
            id: 3,
            name: "подтверждён в производство",
            color: "info",
            roles: [9, 3]
        },
        {
            id: 4,
            name: "в работе",
            color: "yellow",
            roles: [3, 4]
        },
        {
            id: 5,
            name: "выполнен",
            color: "success",
            roles: [3, 4]
        },
        {
            id: 6,
            name: "Готов к отгрузке",
            color: "yellow",
            roles: [3, 4]
        },
        {
            id: 7,
            name: "отгружен",
            color: "success",
            roles: [3, 4]
        },
        {
            id: 8,
            name: "отменен",
            color: "danger",
            roles: [3, 9]
        },
        {
            id: 9,
            name: "Проблемный заказ",
            color: "danger",
            roles: [3, 9]
        }
    ],
    ordersChangeStatus: null,
    productionTaskChangeStatus: null,
    orderAddForm: {
        client: "",
        client_search: "",
        client_search_tel: "",
        client_name: "",
        clients: [],
        validated: false,
        leftovers: [],
        isAccessToCreate: false,
        title: ''
    },
    clients: [],
    clientsForm: {
        name: "",
        phone_code: "+38",
        phone_number: "",
        phone: "",
        email: "",
        group: "",
        adress: "",
        validated: false
    },
    clientsDetail: {},
    clientsDetailOrders: [],
    clientsDetailEdit: false,
    productionTaskTab: 1,
    productionTaskOrders: {
        for: [],
        in: [],
        complete: [],
        forShipping: [],


    },
    currency: [],
    managers: [],
    users: [],
    reports: {
        sale: [],
        saleForm: {
            date_from: currentDate,
            date_to: nextDate,
            currency: 2,
            manager: manager,
            paymount: ''
        },
        saleOpen: 0
    },
    notifyOpen: false,
    notify: [],
    ordersProductsCounts: null,
    printDocuments: {
        isOpen: false,
        order_blank_short: false,
        order_blank_short_without: false,
        cards: false,
        material_list: false,
        xnc: false
    },
    setProblem: {
        isOpen: false,
        reason: null,
        order: null
    },
    viewProgramm: {
        isOpen: false,
        order: null
    },
    alert: {
        active: false,
        message: ""
    },
    isLoading: false,
    terms: false,
    new_clients: false,
    orders_check_enable: '',
    settings_project: null,
    fileManager: {
        isOpen: false,
        listFiles: [],
        addedFiles: {},
        isAddedFiles: false
    },
    paymount_status: {},
    news: NEWS_INITIAL_VALUES

};

const AccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case MAIN_ORDER_DETAIL_WHOLESALE_INFORMATION_BY_GOODS_ID: {
            return {
                ...state,
                order_detail_wholesale_goods_information: action.payload
            }
        }
        case MAIN_ORDER_DETAIL_WHOLESALE_GOODS: {
            return {
                ...state,
                order_detail_wholesale_goods: {
                    ...state.order_detail_wholesale_goods,
                    ...action.payload
                }
            }
        }
        case CONFIRM_MODAL_TOGGLE: {
            return {
                ...state,
                confirm_modal_common: {
                    ...state.confirm_modal_common,
                    ...action.payload
                }
            }
        }
        case MAIN_SHEET_DATA_PRINT_CONFIRM_MODAL: {
            return {
                ...state,
                sheet_data_print_confirm_modal: {
                    ...state.sheet_data_print_confirm_modal,
                    ...action.payload
                }
            }
        }
        case FETCHING_ORDERS_STATUS: {
            return {
                ...state, fetchingOrders: {
                    ...state.fetchingOrders,
                    ...action.payload
                }
            }
        }
        case PROJECT_TASK_ALL_COUNTS_ORDERS: {

            return {
                ...state,
                productionTaskCounts: {
                    ...state.productionTaskCounts,
                    [action.payload.key]: {
                        count: action.payload.count,
                        count_all: action.payload.count_all,
                    }
                }
            }
        }
        case UPDATE_SETTINGS:
            return {
                ...state,
                order: {
                    ...state.order,
                    production_constants: action.production_constants
                }
            };
        case TOGGLE:
            return {
                ...state,
                settings: state.settings.map((el) => {
                    if (Number(el?.id) === Number(action.id)) {
                        console.log('true change el')
                        return {
                            ...el,
                            check: !el.check
                        }
                    }
                    return el
                })
            };
        case SET_TOGGLE: {
            return {
                ...state,
                settings: action.data
            }
        }
        case RESET_FILTERS:
            return {
                ...state,
                ordersForm: {
                    ...INITIAL_ORDER_FORM
                }
            };
        case SET_CHEXBOX:
            let list = [...state.productionTaskOrders.for];
            list = list.map((e) => {
                if (Number(e.id) === Number(action.check.id)) {
                    e = {...action.check, isChecked: !action.check.isChecked};
                    return e;
                } else {
                    return e;
                }
            });
            return {
                ...state,
                productionTaskOrders: {
                    ...state.productionTaskOrders,
                    for: list
                }
            };

        case TOGGLE_SELECT_BY_MATERIAL:
            let selected = [...state.productionTasksMaterials];
            selected.map((el) => {
                return {
                    ...el,
                    orders: el.orders.map((order) => {
                        let isChecked = order.isChecked;
                        if (Number(order.id) === Number(action.check.id)) {
                            action.check.isChecked = !isChecked;
                            return {
                                ...order,
                                isChecked
                            };
                        }
                    })
                };
            });
            break;
        case TOGGLE_SELECT_BY_MATERIAL_GROUP: {
            let material = [...state.productionTasksMaterials];
            const update = material.map((el) => {
                return {
                    ...el,
                    orders: el.orders.map((order) => {
                        if (Number(order.id) === Number(action.payload.id)) {
                            return {
                                ...order,
                                isChecked: action.payload.value
                            };
                        }
                        return order
                    })
                };
            });

            return {
                ...state,
                productionTasksMaterials: update
            };

        }
        case TOGGLE_SIDBAR_VIEW:
            return {
                ...state,
                sidebarCollapse: action.view
            };
        case TOGGLE_MENU_ITEM_OPEN: {
            let active = state?.menu?.active === action.menu ? '' : action.menu;
            return {
                ...state,
                menu: {
                    ...state.menu,
                    active: active
                }
            };
        }

        case CHANGE_PRINT_ALL_DOCUMENTS_DATA:
            let print_documents_data = {...state.printDocuments};
            print_documents_data[action.input] = action.value;
            return {
                ...state,
                printDocuments: print_documents_data
            };
        case TOGGLE_MODAL_SET_PROBLEM:
            return {
                ...state,
                setProblem: {
                    ...state.setProblem,
                    isOpen: action.status,
                    order: action.order
                }
            };
        case TOGGLE_MODAL_VIEW_PROGRAMM:
            return {
                ...state,
                viewProgramm: {
                    ...state.viewProgramm,
                    isOpen: action.status,
                    order: action.order
                }
            };
        case CAHNGE_SET_PROBLEM_MODAL_REASON:
            return {
                ...state,
                setProblem: {
                    ...state.setProblem,
                    reason: action.reason
                }
            };
        case TOGGLE_NOTIFY_OPEN_STATUS:
            return {
                ...state,
                notifyOpen: action.status
            };
        case ORDERS_PRODUCTS_COUNTS_DATA:
            return {
                ...state,
                ordersProductsCounts: action.counts
            };
        case SET_NOTIFY_DATA:
            return {
                ...state,
                notify: action.notify
            };
        case SET_ACTUAL_ORDERS_DATA:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    actual: action.orders
                }
            };
        case SET_STATISICS:
            return {
                ...state,

                statistics: action.statistic
            };
        case SET_STATISICS_SINGLE:
            return {
                ...state,

                single: action.single
            };
        case SET_PRODUCTION_TASK_TYPES:
            return {
                ...state,

                productionTasksTypes: action.prodTaskType
            };

        case SET_PRODUCTION_TASK_BY_MATERIAL:
            return {
                ...state,

                productionTasksMaterials: action.prodTaskMaterial
            };

        case SET_ARCHIVE_ORDERS_DATA:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    archive: action.orders
                }
            };
        case SET_FIRM_DATA_MANAGERS:
            return {
                ...state,
                managers: action.managers
            };
        case SET_FIRM_DATA_USERS:
            return {
                ...state,
                users: action.users
            };
        case CHANGE_ORDERS_FILTER_FORM_DATA:
            let form_order = {...state.ordersForm};
            form_order[action.input] = action.value;
            return {
                ...state,
                ordersForm: form_order
            };
        case CHANGE_PRODUCTIONTASKS_FILTER_FORM_DATA:
            let productionTasks_order = {...state.productionTasksForm};
            productionTasks_order[action.input] = action.value;
            return {
                ...state,
                productionTasksForm: productionTasks_order
            };
        case CHANGE_DASHBOARD_FILTER_FORM_DATA:
            let dashboard = {...state.dashboardForm};
            dashboard[action.input] = action.value;
            return {
                ...state,
                dashboardForm: dashboard
            };
        case CHANGE_DASHBOARD_FILTER_FORM_DATA_SINGLE:
            let dashboardsingle = {...state.dashboardFormSingle};
            dashboardsingle[action.input] = action.value;
            return {
                ...state,
                dashboardFormSingle: dashboardsingle
            };

        case TOGGLE_LOADER_STATUS:
            return {
                ...state,
                isLoading: action.status
            };
        case SET_ORDER_DETAIL_DATA:
            return {
                ...state,
                order_detail: action.order
            };

        case SET_PODUCTION_TASK_DETAIL_DATA:
            return {
                ...state,
                production_task_detail: action.order
            };
        case CHANGE_ORDER_DETAIL_MATERIAL_CALC_TYPES:
            let calc_types = [...state.order_detail.project_data.calculate_types];
            calc_types.map((e) => {
                if (Number(e.id) === Number(action.id)) {
                    e.calc_type = action.calc_type;
                }
                return e;
            });
            return {
                ...state,
                order_detail: {
                    ...state.order_detail,
                    project_data: {
                        ...state.order_detail.project_data,
                        calculate_types: calc_types
                    }
                }
            };
        case TOGGLE_ORDER_DETAIL_DISCOUNT_EDIT:
            return {
                ...state,
                orderDiscountsEdit: action.status
            };
        case SUBMIT_ADD_ORDER_FORM:
            return {
                ...state,
                orderAddForm: initialState.orderAddForm
            };
        case SET_REPORTS_DATA_SALE:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    sale: action.data
                }
            };
        case SET_CURRENCIES_DATA:
            return {
                ...state,
                currency: action.currency
            };
        case TOGGLE_REPORTS_DATA_SALE_ACTIVE:
            let sale_active_ids =
                Number(state.reports.saleOpen) === Number(action.id) ? 0 : action.id;
            return {
                ...state,
                reports: {
                    ...state.reports,
                    saleOpen: sale_active_ids
                }
            };
        case SET_ORDER_DETAIL_DICSOUNTS_DATA:
            return {
                ...state,
                orderDiscounts: {
                    goods: [],
                    material: action.data.materials,
                    service: action.data.services
                }
            };
        case SET_ORDER_DETAIL_MATERIAL_DISCOUNT:
            return {
                ...state,
                orderDiscounts: {
                    goods: [...state.orderDiscounts.goods],
                    material: [...state.orderDiscounts.material].map((e) => {
                        if (Number(e.id) === Number(action.id)) {
                            e.price = action.price;
                        }
                        return e;
                    }),
                    service: [...state.orderDiscounts.service]
                }
            };
        case SET_ORDER_DETAIL_SERVICE_DISCOUNT:
            return {
                ...state,
                orderDiscounts: {
                    goods: [...state.orderDiscounts.goods],
                    material: [...state.orderDiscounts.material],
                    service: [...state.orderDiscounts.service].map((e) => {
                        if (Number(e.id) === Number(action.id)) {
                            e.price = action.price;
                        }
                        return e;
                    })
                }
            };
        case TOGGLE_ORDER_CHANGE_STATUS:
            return {
                ...state,
                ordersChangeStatus: action.id
            };
        case TOGGLE_PRODUCTIONTASK_CHANGE_STATUS:
            return {
                ...state,
                productionTaskChangeStatus: action.id
            };
        case TOGGLE_ORDER_DETAIL_FORM_EDIT:
            return {
                ...state,
                orderDetailEdit: action.status
            };
        case CHANGE_CLIENT_FORM_DATA:
            let form = {...state.clientsForm};
            form[action.input] = action.value;

            if (action.input === "phone_code" || action.input === "phone_number") {
                form.phone = form.phone_code + " " + form.phone_number;
            }
            form.validated = true;
            return {
                ...state,
                clientsForm: form
            };
        case CHANGE_ORDERS_ADD_FORM_VALUE:
            let form_order_add = {...state.orderAddForm};
            let setting_project_make_start_title = !!Number(state?.settings_project?.['production.project_make_start_name'])
            if (action.input === "client") {
                let name = form_order_add.clients.filter(
                    (e) => Number(e.id) === Number(action.value)
                )[0].name;
                form_order_add["client_name"] = name
                form_order_add["title"] = setting_project_make_start_title ? name : 'new order'
                form_order_add["clients"] = [];
                form_order_add["client_search"] = "";
                form_order_add["client_search_tel"] = "";
            }
            form_order_add[action.input] = action.value;
            form_order_add.validated = true;
            return {
                ...state,
                orderAddForm: form_order_add
            };
        case CHANGE_REPORTS_SALE_FORM_VALUE:
            let form_reports_sale = {...state.reports.saleForm};
            form_reports_sale[action.input] = action.value;
            return {
                ...state,
                reports: {
                    ...state.reports,
                    saleForm: form_reports_sale
                }
            };
        case CHANGE_ORDER_DETAIL_DATA_VALUE:
            let form_order_detail = {...state.order_detail};
            switch (action.input) {
                case "client_phone_main":
                    form_order_detail.client.phone = action.value;
                    break;
                case "client_phone_questions":
                    form_order_detail.client.phone_questions = action.value;
                    break;
                case "client_phone_sms":
                    form_order_detail.client.phone_sms = action.value;
                    break;
                default:
                    form_order_detail[action.input] = action.value;
            }
            return {
                ...state,
                order_detail: form_order_detail
            };
        case SUBMIT_CLIENTS_FORM:
            return {
                ...state,
                clientsForm: initialState.clientsForm
            };
        case SET_ALERT_MESSAGE:
            return {
                ...state,
                alert: {
                    active: action.active,
                    message: action.message,
                    type_message: action.type_message
                }
            };
        case SET_CLIENTS_DATA:
            return {
                ...state,
                clients: action.clients
            };
        case SET_MATERIALS:
            return {
                ...state,
                ordersForm: {
                    ...state.ordersForm,
                    materials: action.materials
                }
            };
        case SET_SERVICE:
            return {
                ...state,
                ordersForm: {
                    ...state.ordersForm,
                    services: action.services
                }
            };
        case SET_PRODUCTION_TASK_ACTIVE_TAB:
            return {
                ...state,
                productionTaskTab: action.tab
            };
        case SET_PRODUCTION_TASK_ORDERS_FOR:
            return {
                ...state,
                productionTaskOrders: {
                    ...state.productionTaskOrders,
                    for: action.orders
                }
            };
        case SET_PRODUCTION_TASK_ORDERS_IN:
            return {
                ...state,
                productionTaskOrders: {
                    ...state.productionTaskOrders,
                    in: [...action.orders]
                }
            };
        case SET_PRODUCTION_TASK_ORDERS_COMPLETE:
            return {
                ...state,
                productionTaskOrders: {
                    ...state.productionTaskOrders,
                    complete: action.orders
                }
            };
        case SET_PRODUCTION_TASK_ORDERS_FOR_SHIPPING:
            return {
                ...state,
                productionTaskOrders: {
                    ...state.productionTaskOrders,
                    forShipping: action.orders
                }
            };
        case SET_CLIENT_DETAIL_DATA:
            return {
                ...state,
                clientsDetail: action.client
            };
        case SET_CLIENT_DETAIL_ORDERS_DATA:
            return {
                ...state,
                clientsDetailOrders: action.orders
            };
        case TOOGLE_CLIENT_DETAIL_EDIT:
            return {
                ...state,
                clientsDetailEdit: action.status
            };
        case CHANGE_CLIENT_DETAIL_DATA:
            let client_detail = {...state.clientsDetail};
            client_detail[action.input] = action.value;
            return {
                ...state,
                clientsDetail: client_detail
            };

        case SET_TERMS_DASHBOARD:
            return {
                ...state,
                terms: action.terms
            };
        case SET_NEW_CLIENTS_DASHBOARD:
            return {
                ...state,
                new_clients: action.new_clients
            };
        case SET_SETTINGS_1C_DATA:
            return {
                ...state,
                orders_check_enable: action.orders_check_enable
            };
        case SET_SETTINGS_PROJECT_DATA: {
            return {
                ...state,
                settings_project: action.settings_project
            }
        }
        case SET_LEFTOVERS_ORDER_ADD_FROM: {
            let {stock, isAccessToCreate} = action.payload;
            return {
                ...state,
                orderAddForm: {
                    ...state.orderAddForm,
                    leftovers: stock,
                    isAccessToCreate
                }
            }
        }
        case SET_ACCESS_TO_CREATE_ORDER_ADD_FROM: {
            return {
                ...state,
                orderAddForm: {
                    ...state.orderAddForm,
                    isAccessToCreate: action.payload
                }
            }
        }
        case CLEAR_ACTUAL_FORM_SEARCH: {
            return {
                ...state,
                ordersForm: action.payload
                // orders: {
                //     actual: []
                // }
            }
        }
        case FILE_MANAGER_MODAL_DT: {
            return {
                ...state,
                fileManager: {
                    ...state.fileManager,
                    ...action.payload
                }
            }
        }
        case SET_PAYMOUNT_STATUS: {
            return {
                ...state,
                paymount_status: {
                    ...action.paylaod
                }
            }
        }
        case DELETE_ORDER_ID: {
            const actual = state?.orders?.actual?.filter(item => Number(item.id) !== Number(action.paylaod));
            return {
                ...state,
                orders: {
                    ...state?.orders,
                    actual: actual
                }
            }
        }
        case PROJECT_PRODUCTION_ALL_MATERIALS: {
            return {
                ...state,
                projectProductionAllMaterials: action.payload
            }
        }
        case PROJECT_PRODUCTION_SET_MATERIAL_ORDER: {
            return {
                ...state,
                productionTasksMaterials: action.payload
            }
        }
        case UPDATE_COMMENT_OF_ORDER: {
            console.log(action.payload)
            const order = state.order_detail;
            console.log(order);
            return {
                ...state, order_detail: {...order, comment: action.payload}
            }
        }
        case NEWS_SET_LIST: {
            return {
                ...state,
                news: {
                    ...state.news,
                    ...action.payload
                }
            }
        }
        default:
            return state;
    }
};

export const ConfirmCommonModalAC = ({isOpen = false, message = null, onConfirm = null}) => {
    return {
        type: CONFIRM_MODAL_TOGGLE,
        payload: {
            isOpen: isOpen, message: message, onConfirm: onConfirm
        }
    }
}
const FetchingOrdersAc = (payload) => ({
    type: FETCHING_ORDERS_STATUS, payload: payload
})
const ACProjectTaskSetCounts = ({key, count, count_all}) => {
    return {
        type: PROJECT_TASK_ALL_COUNTS_ORDERS,
        payload: {
            key, count, count_all
        }
    }
}
const ACProjectProductionAllMaterials = (materials) => {
    return {
        type: PROJECT_PRODUCTION_ALL_MATERIALS,
        payload: materials
    }
}
export const ACProjectProductionSetMaterialOrders = (materials) => {
    return {
        type: PROJECT_PRODUCTION_SET_MATERIAL_ORDER,
        payload: materials
    }
}
const ACUpdateCommentOfOrder = (comment) => {
    return {
        type: UPDATE_COMMENT_OF_ORDER,
        payload: comment
    }
}
const deleteOrderId = (id) => {
    return {
        type: DELETE_ORDER_ID,
        paylaod: id
    }
}

const setPaymountStatusConst = (data) => {
    return {
        type: SET_PAYMOUNT_STATUS,
        paylaod: data
    }
}
export const toggleSideBarTypeAC = (view) => {
    return {
        type: TOGGLE_SIDBAR_VIEW,
        view: view
    };
};
export const toggleActicveMenuAC = (menu, status) => {
    return {
        type: TOGGLE_MENU_ITEM_OPEN,
        menu: menu,
        status: status
    };
};
export const toggleLangMenuAC = (status) => {
    return {
        type: TOGGLE_LANG_MENU,
        status: status
    };
};
export const setOrdersCountsDataAC = (counts) => {
    return {
        type: ORDERS_PRODUCTS_COUNTS_DATA,
        counts: counts
    };
};
export const setCurrenciesDataAC = (currency) => {
    return {
        type: SET_CURRENCIES_DATA,
        currency: currency
    };
};
export const setDataManagersAC = (managers) => {
    return {
        type: SET_FIRM_DATA_MANAGERS,
        managers: managers
    };
};
export const setDataUsersAC = (users) => {
    return {
        type: SET_FIRM_DATA_USERS,
        users: users
    };
};
export const setCurrencyDataThunk = () => (dispatch) => {
    apiService.getCurrency().then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setCurrenciesDataAC(res));
    });
};
export const setReportsSaleDataAC = (data) => {
    return {
        type: SET_REPORTS_DATA_SALE,
        data: data
    };
};
export const toggleReportsSaleActiveDataAC = (id) => {
    return {
        type: TOGGLE_REPORTS_DATA_SALE_ACTIVE,
        id: id
    };
};
export const toggleOrderDetailEditAC = (status) => {
    return {
        type: TOGGLE_ORDER_DETAIL_FORM_EDIT,
        status: status
    };
};
export const toggleNotifyOpenStatusAC = (status) => {
    return {
        type: TOGGLE_NOTIFY_OPEN_STATUS,
        status: status
    };
};
export const setNotifyDataAC = (notify) => {
    return {
        type: SET_NOTIFY_DATA,
        notify: notify
    };
};
export const setNotifyDataThunk = () => (dispatch) => {
    apiService.getNotify().then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setNotifyDataAC(res ? res : []));
    });
};
export const updateNotifyDataThunk = (notify_id, link) => (dispatch) => {
    apiService.updateNotify(notify_id).then((res) => {
        apiService.getNotify().then((res) => {
            if (res && res.error) {
                dispatch(setAlertMessageThunk(res.error, "error"));
            }
            dispatch(setNotifyDataAC(res ? res : []));
            dispatch(toggleNotifyOpenStatusAC(false));
            if (link) {
                window.location.href = link;
            }
        });
    });
};
export const changeReportsSaleFilterValueThunk =
    (input, value, type, dates) => (dispatch) => {
        dispatch(toggleLoaderStatusAC(true));
        dispatch(changeReportsSaleFormAC(input, value));
        dates[input] = value;
        dispatch(
            setReportsDataSaleThunk(type, dates));
    };
export const setReportsDataSaleThunk = (type, dates) => (dispatch) => {
    let type_id = 0;
    switch (type) {
        case "sale":
            type_id = 1;
            break;
        case "clients":
            type_id = 2;
            break;
        case "status":
            type_id = 3;
            break;
        case "totals":
            type_id = 10;
            break;
        case "leftMake":
            type_id = 11;
            break;
    }
    dispatch(toggleLoaderStatusAC(true));
    apiService.getReport(type_id, dates).then((res) => {
        if (res && res.error_api) {
            let result = res.error_api.api_error[0].error;
            let err = result.split("->");
            // localStorage.removeItem("i_furn_user_id");
            // localStorage.removeItem("i_furn_user_firm");
            // <Redirect to="/" />;
            dispatch(setAlertMessageThunk(err[1], "error"));
            dispatch(toggleLoaderStatusAC(false));
        } else {
            dispatch(setReportsSaleDataAC(res.data));
            dispatch(toggleLoaderStatusAC(false));
        }
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};
export const toggleOrderDetailEditThunk =
    (status, order_id, data) => (dispatch) => {
        dispatch(toggleLoaderStatusAC(true));
        if (status) {
            dispatch(toggleOrderDetailEditAC(status));
        } else {
            apiService
                .updateOrder(order_id, {
                    client_phone_main: data.client.phone,
                    client_phone_questions: data.client.phone_questions,
                    client_phone_sms: data.client.phone_sms,
                    name: data.name
                })
                .then((res) => {
                    if (res && res.error) {
                        dispatch(setAlertMessageThunk(res.error, "error"));
                    }
                    dispatch(setOrderDetailDataThunk(order_id));
                    dispatch(toggleOrderDetailEditAC(status));
                }).catch(e => {
                dispatch(toggleLoaderStatusAC(false));
            });
        }
        dispatch(toggleLoaderStatusAC(false));
    };
export const setOrdersDataAC = (orders, archive) => {
    // if (archive) {
    //   return {
    //     type: SET_ARCHIVE_ORDERS_DATA,
    //     orders: orders
    //   };
    // } else {
    return {
        type: SET_ACTUAL_ORDERS_DATA,
        orders: orders
        // };
    };
};

export const setStatisticsDataAc = (statistic) => {
    return {
        type: SET_STATISICS,
        statistic: statistic
    };
};

export const setStatisticsSingleDataAc = (single) => {
    return {
        type: SET_STATISICS_SINGLE,
        single: single
    };
};
export const setProductionTasksType = (prodTaskType) => {
    return {
        type: SET_PRODUCTION_TASK_TYPES,
        prodTaskType: prodTaskType
    };
};

export const setProductionTasksByMaterial = (prodTaskMaterial) => {
    return {
        type: SET_PRODUCTION_TASK_BY_MATERIAL,
        prodTaskMaterial: prodTaskMaterial
    };
};

let IS_BLOCKED_REQUEST_ORDERS = true;
export const setOrdersDataThunk =
    (filters = false, archive = 0) =>
        (dispatch, getState) => {
            const loading = getState().main.isLoading;
            dispatch(FetchingOrdersAc({
                isPending: true,
                isResolved: false
            }))
            dispatch(toggleLoaderStatusAC(true));
            if (IS_BLOCKED_REQUEST_ORDERS) {
                dispatch(toggleLoaderStatusAC(true));
                apiService.getOrders(0, filters).then((res) => {
                    dispatch(toggleLoaderStatusAC(true));
                    if (res && res.error) {
                        dispatch(setAlertMessageThunk(res.error, "error"));
                    }
                    if (res && res.custom_error) {
                        dispatch(setAlertMessageThunk(res.custom_error, "error"));
                        return
                    }
                    dispatch(setOrdersDataAC(res ? res : [], archive));
                    setTimeout(() => {
                        dispatch(FetchingOrdersAc({
                            isPending: false,
                            isResolved: true
                        }))
                        dispatch(toggleLoaderStatusAC(false));
                    }, 2000);

                }).catch(e => {
                    dispatch(toggleLoaderStatusAC(false));
                })
            }
        };

export const setStatisticsDataThunk = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getStatistics().then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }

        dispatch(setStatisticsDataAc(res ? res : [], 0));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};
export const setTermsDashboardDataAC = (terms) => {
    return {
        type: SET_TERMS_DASHBOARD,
        terms
    }
}
export const setTermsDashboardDataThunk = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getTermsDashboard().then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setTermsDashboardDataAC(res?.terms ?? false));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};
export const setNewClientsDashboardDataAC = (new_clients) => {
    return {
        type: SET_NEW_CLIENTS_DASHBOARD,
        new_clients
    }
}
export const setNewClientsDashboardDataThunk = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getNewClientsDashboard().then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setNewClientsDashboardDataAC(res?.new_clients ?? false));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};

export const setStatisticsSingleDataThunk = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getStatisticsSingle().then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setStatisticsSingleDataAc(res ? res : [], 0));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};

export const setProblemModalUpdateThunk = (order_id, reason) => (dispatch) => {
    if (reason && reason.length > 4) {
        dispatch(toggleLoaderStatusAC(true));
        apiService
            .updateOrder(order_id, {status: 9, reason: reason})
            .then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(toggleOrderChangeStatusAC(null));
                dispatch(setOrdersDataThunk({status: 9, limit: 1}));
                dispatch(setProductionsTaskOrdersThunk());
                dispatch(toggleSetProblemModalAC(false, null));
                dispatch(toggleViewProgrammModalAC(false, null));
                dispatch(toggleLoaderStatusAC(false));
            }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        });
    }
};

export const setOrderDetailDataAC = (order) => {
    return {
        type: SET_ORDER_DETAIL_DATA,
        order: order
    };
};

export const setProductionTaskDetailDataAC = (order) => {
    return {
        type: SET_PODUCTION_TASK_DETAIL_DATA,
        order: order
    };
};

export const toggleOrdersDiscountEditAC = (status) => {
    return {
        type: TOGGLE_ORDER_DETAIL_DISCOUNT_EDIT,
        status: status
    };
};
export const setOrderDiscountsDataAC = (data) => {
    return {
        type: SET_ORDER_DETAIL_DICSOUNTS_DATA,
        data: data
    };
};
export const setOrderDetailItemDiscount = (id, type, price) => {
    if (type === "material") {
        return {
            type: SET_ORDER_DETAIL_MATERIAL_DISCOUNT,
            id: id,
            price: price
        };
    }
    if (type === "service") {
        return {
            type: SET_ORDER_DETAIL_SERVICE_DISCOUNT,
            id: id,
            price: price
        };
    }
};
export const toggleOrdersDiscountEditThunk =
    (status, calculate, currency, order, discounts, version, total, orderID) =>
        (dispatch) => {
            if (status) {
                dispatch(
                    setOrderDiscountsDataAC({
                        materials: calculate.material?.map((e) => {
                            return {
                                id: e.id,
                                price: null,
                                currency: currency.id
                            };
                        }),

                        services: calculate.service?.map((e) => {
                            return {
                                id: e.service_id,
                                price: null,
                                currency: currency.id
                            };
                        })
                    })
                );
            } else {
                dispatch(toggleLoaderStatusAC(true));
                apiService.getCalculate(discounts, order).then((res) => {
                    let resCalc = res ? {...res} : {};
                    delete resCalc?.api_error_calc;
                    delete resCalc?.calc_update;
                    delete resCalc?.error;

                    if (res && res.error) {
                        dispatch(setAlertMessageThunk(res.error, "error"));
                        dispatch(toggleLoaderStatusAC(false));
                        return false;
                    }
                    order.calc = res.calc;
                    order.calculate_vals = res.vals;
                    order.calculate = {
                        ...resCalc,
                        goods: res.goods ? res.goods : [],
                        material: res.material ? res.material : [],
                        service: res.service
                            ? res.service.filter((e) => Number(e.count) > 0)
                            : []
                    };
                    apiService.updateProject(order, version).then((rec) => {
                        if (rec && rec.error) {
                            dispatch(setAlertMessageThunk(res.error, "error"));

                        }
                        apiService.confirmOrder(order, total).then((rez) => {
                            dispatch(setOrderDetailDataThunk(orderID));
                            // dispatch(toggleLoaderStatusAC(false));
                        });
                    }).catch(e => {
                        dispatch(toggleLoaderStatusAC(false));
                    });
                }).catch(e => {
                    dispatch(toggleLoaderStatusAC(false));
                });
            }
            dispatch(toggleOrdersDiscountEditAC(status));
        };
export const changeOrdersFilterFormValueAC = (input, value) => {
    return {
        type: CHANGE_ORDERS_FILTER_FORM_DATA,
        input: input,
        value: value
    };
};

export const changePrintDocumentsDataAC = (input, value) => {
    return {
        type: CHANGE_PRINT_ALL_DOCUMENTS_DATA,
        input: input,
        value: value
    };
};
export const changeReportsSaleFormAC = (input, value) => {
    return {
        type: CHANGE_REPORTS_SALE_FORM_VALUE,
        input: input,
        value: value
    };
};
export const getOrdersFilteredDataThunk =
    (input, value, filters) => (dispatch) => {
        console.log(filters)
        dispatch(toggleLoaderStatusAC(true));
        dispatch(changeOrdersFilterFormValueAC(input, value));
        filters[input] = value;
        dispatch(setOrdersDataThunk(filters));
        dispatch(toggleLoaderStatusAC(false));
    };
export const updateArrayOrderDataThunk = (orders_ids, data, status_update = "actual", cb) => async (dispatch, getState) => {
    dispatch(toggleLoaderStatusAC(true));
    const form = getState().main.ordersForm;
    try {
        let requests = orders_ids?.map(ids => apiService.updateOrder(ids, data));
        const response = await Promise.all(requests);

        let errors = response?.filter(item => item?.error_api?.api_error?.[0]?.error)?.join('')
        if (errors) {
            dispatch(setAlertMessageThunk(errors, "error"));

        } else if (cb) {
            dispatch(setOrdersDataThunk({...form, status: status_update, limit: 1}));
            dispatch(setProductionsTaskOrdersThunk());
            cb();
        }


        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(setAlertMessageThunk(e.message, "error"));
        dispatch(toggleLoaderStatusAC(false));
    }
}
export const updateOrderDataThunk =
    (order_id, data, status_update = "actual") =>
        async (dispatch, getState) => {
            let {from_status = null, ...reset} = data;

            console.log('form_status', from_status)
            console.log('reset', reset)
            const form = getState().main.ordersForm;
            dispatch(toggleLoaderStatusAC(true));
            apiService.updateOrder(order_id, reset).then(async (res) => {
                    if (res && res.error) {
                        dispatch(setAlertMessageThunk(res.error, "error"));
                    }
                    if (res?.error_api) {
                        let error = res?.error_api?.api_error?.[0]?.error;
                        dispatch(setAlertMessageThunk(error, "error"));

                    }
                    dispatch(toggleOrderChangeStatusAC(null));
                    // dispatch(toggleLoaderStatusAC(true));
                    //::TODO: Передаю свойства с формы для корректного отображения по фильтру после обновления статуса заказа
                    // dispatch(setOrdersDataThunk({...form, status: status_update, limit: 1}));
                    const orders_res = await apiService.getOrders(0, {...form, status: status_update, limit: 1});
                    if (orders_res && orders_res.error) {
                        dispatch(setAlertMessageThunk(orders_res.error, "error"));
                    } else {
                        dispatch(setOrdersDataAC(orders_res ? orders_res : []));
                    }


                    await fetchingProductionOrdersForStatus({
                        from_status: from_status,
                        // to_status: null
                        to_status: reset.status
                    }, dispatch)

                    dispatch(toggleLoaderStatusAC(false));
                }
            ).catch(e => {
                dispatch(setAlertMessageThunk(e.message, "error"));
                dispatch(toggleLoaderStatusAC(false));
            });
        }
;

export const initialProductionOrdersPage = () => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const order_for = await apiService.getProductionTask("for");
        if (order_for && order_for.error) {
            dispatch(setAlertMessageThunk(order_for.error, "error"));
        } else {
            dispatch(orders_for(order_for || []));
        }
        apiService.getProductionTaskType(false).then((res) => {
            if (res && res.error) {
                dispatch(setAlertMessageThunk(res.error, "error"));
            }
            dispatch(setProductionTasksType(res ? res : []));
        })

        await dispatch(setClientsDataThunk(false));
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(setAlertMessageThunk(e.message, "error"));
        dispatch(toggleLoaderStatusAC(false));
    }
}

const fetchingProductionOrdersForStatus = async ({from_status, to_status}, dispatch) => {

    const status_req_type = {
        'status_3': 'for',
        'status_4': 'in',
        'status_5': 'complete',
        'status_6': 'forShipping',

    };
    console.log(from_status, to_status)
    if (status_req_type[`status_${from_status}`]) {
        dispatch(setProductionsTaskOrders([], status_req_type[`status_${from_status}`]))
        let from_res = await apiService.getProductionTask(status_req_type[`status_${from_status}`]);
        if (!from_res) {
            let error = from_res.error || from_res?.error_api?.api_error?.[0]?.error || 'error get production (from)' + from_status;
            dispatch(setAlertMessageThunk(error, "error"));
        }
        if (from_res) {
            dispatch(setProductionsTaskOrders(from_res || [], status_req_type[`status_${from_status}`]))
        }
    }
    if (status_req_type[`status_${to_status}`]) {
        dispatch(setProductionsTaskOrders([], status_req_type[`status_${to_status}`]))
        let to_res = await apiService.getProductionTask(status_req_type[`status_${to_status}`]);
        if (!to_res) {
            let error = to_res.error || to_res?.error_api?.api_error?.[0]?.error || 'error get production (to)' + to_status;
            dispatch(setAlertMessageThunk(error, "error"));
        }
        if (to_res) {
            dispatch(setProductionsTaskOrders(to_res || [], status_req_type[`status_${to_status}`]))
        }
    }
    let res_count = await apiService.getOrdersCounts()
    if (res_count) {
        dispatch(setOrdersCountsDataAC(res_count));
    }
    return new Promise(res => res());
}

/**
 * .
 *
 * @param {object}  -
 * @returns  {Promise}  -
 */
export const groupOrdersTransfer = ({
                                        ids = null,
                                        from_status = null,
                                        to_status = null
                                    }) => async (dispatch, getState) => {
    dispatch(toggleLoaderStatusAC(true));

    const status_req_type = {
        'status_3': 'for',
        'status_4': 'in',
        'status_5': 'complete',
        'status_6': 'forShipping',

    }
    try {
        if (!ids || !from_status || !to_status) throw new Error('payload not empty');
        let direction = from_status < to_status;
        let direction_status = [];
        if (direction) {
            for (let i = from_status + 1; i <= to_status; i++) {
                direction_status.push({
                    status: i
                })
            }
        } else {
            for (let i = from_status - 1; i >= to_status; i--) {
                direction_status.push({
                    status: i
                })
            }
        }

        let req_ids = []
        for (const id of ids) {
            for (const status of direction_status) {
                const result = await apiService.updateOrder(id, status);
                req_ids.push(result);
            }
        }
        const results = await Promise.all(req_ids);
        results.forEach((res) => {
            if (res && res.error) {
                dispatch(setAlertMessageThunk(res.error, "error"));
            }
            if (res?.error_api) {
                let error = res?.error_api?.api_error?.[0]?.error;
                dispatch(setAlertMessageThunk(error, "error"));

            }
        });
        await fetchingProductionOrdersForStatus({from_status, to_status}, dispatch)

        dispatch(toggleLoaderStatusAC(false));

    } catch
        (e) {
        dispatch(setAlertMessageThunk(e.massage, "error"));
        dispatch(toggleLoaderStatusAC(false));
    }
}
export const orderOnUpdateServicesAndUpdateStatus = (order_id, from_status, to_status) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await apiService.updateOrder(order_id, {status: to_status});
        await fetchingProductionOrdersForStatus({from_status, to_status}, dispatch)
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(setAlertMessageThunk(e.massage, "error"));
        dispatch(toggleLoaderStatusAC(false));
    }
}
export const updateProductionTaskDataThunk =
    (order_id, data, status_update = "actual") =>
        (dispatch) => {
            apiService.updateProductionTask(order_id, data).then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(toggleOrderChangeStatusAC(null));
                dispatch(setOrdersDataThunk({status: status_update, limit: 1}));
                dispatch(setProductionsTaskOrdersThunk());
            }).catch(e => {
                dispatch(toggleLoaderStatusAC(false));
            })
        };

export const setOrdersCountsDataThunk = () => (dispatch) => {
    apiService.getOrdersCounts().then((res) => {
        if (res) {
            dispatch(setOrdersCountsDataAC(res));
        }
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};
export const updateOrderDetailDataThunk = (order_id, data, redirectTo = false) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.updateOrder(order_id, data).then((res) => {
        apiService.getOrder(order_id).then((res) => {
            if (res && res.error) {
                dispatch(setAlertMessageThunk(res.error, "error"));
            }
            dispatch(setOrderDetailDataAC(res ? res : {}));
            if (redirectTo) window.location.pathname = redirectTo
            dispatch(toggleLoaderStatusAC(false));
        }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        })
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};

export const updateProductionTaskDetailDataThunk =
    (order_id, data) => (dispatch) => {
        dispatch(toggleLoaderStatusAC(true));
        apiService.updateProductionTask(order_id, data).then((res) => {
            apiService.getProductionSingleTask(order_id).then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(setProductionTaskDetailDataAC(res ? res : {}));
                dispatch(toggleLoaderStatusAC(false));
            }).catch(e => {
                dispatch(toggleLoaderStatusAC(false));
            })
        }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        })
    };

export const setOrderDetailDataThunk = (id) => async (dispatch) => {

    try {
        dispatch(toggleLoaderStatusAC(true));
        const res = await apiService.getOrder(id);
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
            dispatch(setOrderDetailDataAC({message_error: res.error}))
            dispatch(toggleLoaderStatusAC(false));
            return
        }
//::TODO оптовая компания
        let isDevelopment = !!Number(process.env.REACT_APP_DEVELOPMENT)
        if (isDevelopment) {
            if (res?.project_data?.calculate?.material?.length && Array.isArray(res?.project_data?.calculate?.material)) {
                let materials_ids = res?.project_data?.calculate?.material?.filter(e => !!Number(e?.id) && !Number(e?.bandClient))?.map(e => e?.id);
                console.log(materials_ids);
                await dispatch(wsGetGoodsInformationByMaterialsIdsThunk(materials_ids));
            }
        }
        await dispatch(getGeneralCurrenciesThunk());
        await dispatch(getAllAdvancedGoodsServiceByOrderIdThunk(id));

        dispatch(setOrderDetailDataAC(res ? res : {}));
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        let error = e?.error || e?.message || 'Error get project'
        dispatch(setOrderDetailDataAC({message_error: error}))
        dispatch(setAlertMessageThunk(error, "error"));
        dispatch(toggleLoaderStatusAC(false));
    }
};

export const setProductionOrderDetailDataThunk = (id) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));

    apiService.getProductionSingleTask(id).then((res) => {
        console.log('res', res)
        if (res && res.error_api) {
            dispatch(setAlertMessageThunk(res.error_api?.api_error?.[0]?.error, "error"));
        }
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setProductionTaskDetailDataAC(res));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};

export const getManagersDataThunk = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));

    apiService.getManagers().then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setDataManagersAC(res ? res : []));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};

export const getUsersDataThunk = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getUsers().then((res) => {
        dispatch(setDataUsersAC(res ? res : []));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};

export const getPDFLoaderSetterThunk = (action, order) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getPDF(action, order).then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        if (res && res.status) {
            dispatch(setAlertMessageThunk(getTranslateMessageAPI("Раскрой успешно обновлен"), "success"));
        }

        if (res && res.error_api) {
            let error_string = "";
            res.error_api &&
            res.error_api.mysql &&
            res.error_api.mysql.forEach((e) => {
                error_string += e.error + ";";
            });
            res.error_api &&
            res.error_api.api &&
            res.error_api.api.forEach((e) => {
                error_string += e.error + ";";
            });
            dispatch(setAlertMessageThunk(error_string, "error"));
        }
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(setAlertMessageThunk(getTranslateMessageAPI('Проблема с калькуляцией - обратитесь к Администрации.'), "error"));
        dispatch(toggleLoaderStatusAC(false));
    });
};

export const fetchingMergeHtmlFiles = ({actions, order}) => async (dispatch, getState) => {
    dispatch(toggleLoaderStatusAC(true));
    const cuttingCard = order?.cutting_card;
    let projectDetail = order;
    // const cuttingCard = getState()?.main.order_detail?.project_data?.cutting_card;
    // let projectDetail = getState().main.order_detail.project_data;
    let contentCutting = '';
    const cuttingHtml = new CuttingHtml();

    function _b64DecodeUnicode(str) {
        return decodeURIComponent(atob(str).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
    }


    const responseBs = await Promise.all(Object.entries(actions)?.filter(([key, value]) => value && key !== 'cutting_card').map(async ([action, _]) => {
        return await apiService.getContentHtmlFile(action, order)
    }));
    let checkError = responseBs?.filter(item => item?.error)?.map(item => {
        return item?.error
    })?.join('.')
    if (checkError) {
        dispatch(setAlertMessageThunk(checkError, "error"));

    }

    if (actions.cutting_card && cuttingCard.hasOwnProperty('cards')) {
        let settings = {
            general: 0,
            cutting_card: {
                ...cuttingCard,
                cards: cuttingCard?.cards?.map(card => ({
                    ...card,
                    visible_xnc: false,
                    visible_card: true
                })) ?? [],
            },
            xnc: 0, mode: 1, modeDetailing: 1, landscapePage: false
        };
        const previewImages = await apiService._getPartsSmallPreviews(order);
        if (previewImages) {
            projectDetail = {
                ...projectDetail, partsPreview: previewImages
            }
        }
        contentCutting = cuttingHtml.generateContentHtml({order, projectDetail, setting: settings})
    }
    let blankContent = responseBs?.filter(item => item?.base)?.map(item => _b64DecodeUnicode(item?.base));
    if (contentCutting) {
        blankContent.push(contentCutting)
    }
    let content = blankContent?.join('');
    mergeWindowHtml.renderHtmlWindow(content, 'iFurn.pro', order);
    dispatch(toggleLoaderStatusAC(false));
}
export const toggleLoaderStatusAC = (status) => {
    return {
        type: TOGGLE_LOADER_STATUS,
        status: status
    };
};
export const submitClientFormAC = () => {
    return {
        type: SUBMIT_CLIENTS_FORM
    };
};
export const removeOrderFromProductionTaskThunk =
    (order_data) => (dispatch) => {
        dispatch(toggleLoaderStatusAC(true));
        apiService.removeOrderFromProductionTask(order_data).then((res) => {
            if (res && res.error_api && res.error_api.api_error) {
                dispatch(
                    setAlertMessageThunk(res.error_api.api_error[0].error, "error")
                );
            }
            dispatch(setProductionOrderDetailDataThunk(order_data.task));
            dispatch(toggleLoaderStatusAC(false));
        }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        });
    };

export const deleteProductionTaskOrderStatus = (id, navigateTo = false) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.deleteProductionTask(id).then((res) => {
        if (res && res.error_api && res.error_api.api_error) {
            dispatch(setAlertMessageThunk(res.error_api.api_error[0].error, "error"));
        }
        if (res?.result && navigateTo) {
            window.location.pathname = navigateTo;
        }
        // dispatch(getProductionsStatusThunk());
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};
export const deleteProductionTaskThunk = (id) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.deleteProductionTask(id).then((res) => {
        if (res && res.error_api && res.error_api.api_error) {
            dispatch(setAlertMessageThunk(res.error_api.api_error[0].error, "error"));
        }
        dispatch(getProductionsStatusThunk());
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};
export const submitClientFormThunk = (form) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.createClient(form).then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        if (res && res.successful === 1) {
            setTimeout(function () {
                dispatch(submitClientFormAC());
                dispatch(setAlertMessageThunk(getTranslateMessageAPI("Клиент успешно добавлен!"), "success"));
            }, 1500);
        } else if (res && Number(res.successful) === 9) {
            dispatch(
                setAlertMessageThunk(
                    getTranslateMessageAPI("Такой email или телефон уже используются"),
                    "error"
                )
            );
        } else if (res && res.error_api.api_error) {
            dispatch(
                setAlertMessageThunk(res.error_api.api_error[0]?.error, "error")
            );
        } else {
            dispatch(setAlertMessageThunk(getTranslateMessageAPI("Ошибка при добавлении клиента"), "error"));
        }
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};
export const changeClientPasswordThunk = (id, data) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.updateClient(id, data).then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(toggleLoaderStatusAC(false));
        dispatch(
            setAlertMessageThunk(
                getTranslateMessageAPI("Пароль клиента успешно обновлен и отправлен ему на почту!"),
                "success"
            )
        );
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};
export const changeClientsFormValueAC = (input, value) => {
    return {
        type: CHANGE_CLIENT_FORM_DATA,
        input: input,
        value: value
    };
};
export const toggleSetProblemModalAC = (status, order) => {
    return {
        type: TOGGLE_MODAL_SET_PROBLEM,
        status: status,
        order: order
    };
};
export const toggleViewProgrammModalAC = (status, order) => {
    return {
        type: TOGGLE_MODAL_VIEW_PROGRAMM,
        status: status,
        order: order
    };
};
export const changeProblemModalReasonAC = (reason) => {
    return {
        type: CAHNGE_SET_PROBLEM_MODAL_REASON,
        reason: reason
    };
};
export const changeOrderAddFormValueAC = (input, value) => {
    return {
        type: CHANGE_ORDERS_ADD_FORM_VALUE,
        input: input,
        value: value
    };
};
export const changeOrderAddFormThunk = (input, value) => (dispatch) => {
    if (input === "client_search" || input === "client_search_tel") {
        let codePhone = value.hasOwnProperty('code') && value.hasOwnProperty('phone') ? value.code + value.phone : value.phone;
        dispatch(toggleLoaderStatusAC(true));
        apiService
            .getClients(
                input === "client_search" ? {name: value} : {phone: codePhone}
            )
            .then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(changeOrderAddFormValueAC("clients", res));
                // dispatch(changeOrderAddFormValueAC("title", res));
                dispatch(changeOrderAddFormValueAC(input, value));
                dispatch(toggleLoaderStatusAC(false));
            }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        });
    } else {
        dispatch(changeOrderAddFormValueAC(input, value));
    }
};
export const setLeftoversOrderAddFormAC = (payload) => {
    return {
        type: SET_LEFTOVERS_ORDER_ADD_FROM,
        payload
    }
}
export const setAccessToCreateOrderAddFormAC = (payload) => {
    return {
        type: SET_ACCESS_TO_CREATE_ORDER_ADD_FROM,
        payload
    }
}
export const setLeftoversOrderAddForm = (clientId) => (dispatch) => {
    let payload = {
        firm: localStorage.getItem('i_furn_user_firm'),
        get_stock: 1,
        client: clientId
    }
    apiService.getLeftoversOrderAddForm(payload)
        .then(res => {

            if (res && res.error_api) {
                dispatch(setAlertMessageThunk(res.error_api.api_error[0].error, "error"));
            } else {
                dispatch(setLeftoversOrderAddFormAC({stock: res.stock, isAccessToCreate: true}))
            }
        })
        .catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        })
}
export const setAlertMessage = (active, message, type_message) => {
    return {
        type: SET_ALERT_MESSAGE,
        active: active,
        message: message,
        type_message: type_message
    };
};
export const setAlertMessageThunk = (message, type_message) => (dispatch) => {
    dispatch(setAlertMessage(true, message, type_message));
    setTimeout(function () {
        dispatch(setAlertMessage(false, "", ""));
    }, 6000);
};
export const setCleintsDataAC = (clients) => {
    return {
        type: SET_CLIENTS_DATA,
        clients: clients
    };
};
export const setMaterialsAC = (materials) => {
    return {
        type: SET_MATERIALS,
        materials: materials
    };
};
export const setServiceAC = (services) => {
    return {
        type: SET_SERVICE,
        services: services
    };
};

export const setClientDataAC = (client) => {
    return {
        type: SET_CLIENT_DETAIL_DATA,
        client: client
    };
};
export const setClientOrdersDataAC = (orders) => {
    return {
        type: SET_CLIENT_DETAIL_ORDERS_DATA,
        orders: orders
    };
};
export const getClientOrdersDataThunk = (id) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getOrders(0, {client: id}).then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setClientOrdersDataAC(res));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};
export const getClientDataThunk = (id) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getClient(id).then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setClientDataAC(res));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};
export const setClientsDataThunk =
    (withLoader = true) =>
        (dispatch) => {
            if (withLoader) {
                dispatch(toggleLoaderStatusAC(true));
            }
            apiService.getClients().then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(setCleintsDataAC(res));
                if (withLoader) {
                    dispatch(toggleLoaderStatusAC(false));
                }
            }).catch(e => {
                dispatch(toggleLoaderStatusAC(false));
            })
        };

export const setMaterialsThunk =
    (withLoader = true) =>
        (dispatch) => {
            if (withLoader) {
                dispatch(toggleLoaderStatusAC(true));
            }
            apiService.getMaterialsFilters().then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(setMaterialsAC(res));
                if (withLoader) {
                    dispatch(toggleLoaderStatusAC(false));
                }
            }).catch(e => {
                dispatch(toggleLoaderStatusAC(false));
            })
        };

export const setServiceThunk =
    (withLoader = true) =>
        (dispatch) => {
            if (withLoader) {
                dispatch(toggleLoaderStatusAC(true));
            }
            apiService.getServiceFilters().then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(setServiceAC(res));
                if (withLoader) {
                    dispatch(toggleLoaderStatusAC(false));
                }
            }).catch(e => {
                dispatch(toggleLoaderStatusAC(false));
            })
        };
export const toggleOrderChangeStatusAC = (id) => {
    return {
        type: TOGGLE_ORDER_CHANGE_STATUS,
        id: id
    };
};
export const toggleProductionTaskChangeStatusAC = (id) => {
    return {
        type: TOGGLE_PRODUCTIONTASK_CHANGE_STATUS,
        id: id
    };
};
export const setProductionActiveTabAC = (tab) => {
    return {
        type: SET_PRODUCTION_TASK_ACTIVE_TAB,
        tab: tab
    };
};
export const setProductionsTaskOrders = (orders, type) => {
    if (type === "for") {
        return {
            type: SET_PRODUCTION_TASK_ORDERS_FOR,
            orders: orders
        };
    } else if (type === "in") {
        return {
            type: SET_PRODUCTION_TASK_ORDERS_IN,
            orders: orders
        };
    } else if (type === "complete") {
        return {
            type: SET_PRODUCTION_TASK_ORDERS_COMPLETE,
            orders: orders
        };
    } else if (type === "forShipping") {
        return {
            type: SET_PRODUCTION_TASK_ORDERS_FOR_SHIPPING,
            orders: orders
        };
    }
};

export const orders_for = (orders) => {
    return {
        type: SET_PRODUCTION_TASK_ORDERS_FOR,
        orders: orders
    };
};

export const orders_in = (orders) => {
    return {
        type: SET_PRODUCTION_TASK_ORDERS_IN,
        orders: orders
    };
};

export const orders_complete = (orders) => {
    return {
        type: SET_PRODUCTION_TASK_ORDERS_COMPLETE,
        orders: orders
    };
};

export const orders_for_shipping = (orders) => {
    return {
        type: SET_PRODUCTION_TASK_ORDERS_FOR_SHIPPING,
        orders: orders
    };
};

export const setProductionsTaskOrdersThunk = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getProductionTask("for").then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(setProductionsTaskOrders(res, "for"));
        // dispatch(ACProjectTaskSetCounts({key: 'for', count_all: res.count_all, count: res.count}));
        apiService.getProductionTask("in").then((rez) => {
            if (rez && rez.error) {
                dispatch(setAlertMessageThunk(res.error, "error"));
            }
            dispatch(setProductionsTaskOrders(rez, "in"));
            // dispatch(ACProjectTaskSetCounts({key: 'in', count_all: rez.count_all, count: rez.count}));

            apiService.getProductionTask("complete").then((rec) => {
                if (rec && rec.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(setProductionsTaskOrders(rec, "complete"));
                // dispatch(ACProjectTaskSetCounts({key: 'complete', count_all: rec.count_all, count: rec.count}));

                apiService.getProductionTask("forShipping").then((rec) => {
                    if (rec && rec.error) {
                        dispatch(setAlertMessageThunk(res.error, "error"));
                    }
                    dispatch(setProductionsTaskOrders(rec, "forShipping"));
                    // dispatch(ACProjectTaskSetCounts({key: 'forShipping', count_all: rec.count_all, count: rec.count}));

                    dispatch(toggleLoaderStatusAC(false));
                });
            });
        });
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};
export const OrdersFor = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getProductionTask("for").then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(orders_for(res));
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};

export const OrdersIn = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getProductionTask("in").then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(orders_in(res));

        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};

export const OrdersComplete = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getProductionTask("complete").then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(orders_complete(res));

        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};

export const OrdersForShipping = () => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.getProductionTask("forShipping").then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        dispatch(orders_for_shipping(res));

        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};
export const toggleClientDetailEditAC = (status) => {
    return {
        type: TOOGLE_CLIENT_DETAIL_EDIT,
        status: status
    };
};
export const changeClientDetailDataAC = (input, value) => {
    return {
        type: CHANGE_CLIENT_DETAIL_DATA,
        input: input,
        value: value
    };
};
export const changeOrderDetailValue = (input, value) => {
    return {
        type: CHANGE_ORDER_DETAIL_DATA_VALUE,
        input: input,
        value: value
    };
};
export const updateClientDataThunk = (client_id, client_data) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.editClient(client_id, client_data).then((res) => {
        if (res && (res?.error || res?.error_api)) {
            if (res?.error_api && res?.error_api?.api_error) {
                dispatch(setAlertMessageThunk(res?.error_api?.api_error?.[0]?.error, "error"));
            } else {
                dispatch(setAlertMessageThunk(res.error, "error"));
            }
        } else {
            dispatch(toggleClientDetailEditAC(false));
            dispatch(
                setAlertMessageThunk(getTranslateMessageAPI("Данные клиента успешно обновлены"), "success")
            );
        }
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};
export const submitOrderAddFormAC = () => {
    return {
        type: SUBMIT_ADD_ORDER_FORM
    };
};
export const createNewOrderThunk = (client_id, title) => (dispatch) => {
    let urlGo = new ApiService()._mainLink;

    dispatch(toggleLoaderStatusAC(true));
    apiService.createOrder(client_id, title).then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        if (res.status === "complete" && res.data) {
            //TODO: ссылка под замену (создание нового заказа)
            window.open(
                urlGo + '/project/' +
                // "https://go.ifurn.pro/project/" +
                // "https://test-go.ifurn.pro/project/" +
                // "https://test2-go.ifurn.pro/project/" +
                // "http://go-local.ifurn.pro/project/" +
                res.data.code +
                "/1/" +
                client_id +
                "/" +
                localStorage.getItem("i_furn_user_firm")
                // +
                // "/" +
                //localStorage.getItem("user_id")
            );
            dispatch(setAlertMessageThunk(getTranslateMessageAPI("Заказ успешно создан"), "success"));
        } else {
            dispatch(setAlertMessageThunk(getTranslateMessageAPI("Ошибка при создании заказа"), "error"));
        }
        dispatch(submitOrderAddFormAC());
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};

export const createNewGoOrderThunk = (client_id, title) => (dispatch) => {
    let urlProject = new ApiService()._baseUrlProject;
    // console.log("createNewOrderThunk urlGo", urlProject)
    dispatch(toggleLoaderStatusAC(true));
    apiService.createOrder(client_id, title).then((res) => {
        if (res && res.error) {
            dispatch(setAlertMessageThunk(res.error, "error"));
        }
        if (res.status === "complete" && res.data) {
            gaEvents.customEvent('klik_form', {
                btn_create_order: "create new order"
            })
            //TODO: ссылка под замену (создание нового заказа)
            window.open(
                urlProject +
                // "https://newgo.ifurn.pro/project/" +
                // "https://test-newgo.ifurn.pro/project/" +
                // "https://test2-newgo.ifurn.pro/project/" +
                // "http://go-local.ifurn.pro/project/" +
                // "http://newgo-local.ifurn.pro/project/" +
                res.data.code +
                "/1/" +
                client_id +
                "/" +
                localStorage.getItem("i_furn_user_firm") +
                "/" +
                localStorage.getItem("i_furn_user_id")
            );
            dispatch(setAlertMessageThunk(getTranslateMessageAPI("Заказ успешно создан"), "success"));
        } else {
            dispatch(setAlertMessageThunk(getTranslateMessageAPI("Ошибка при создании заказа"), "error"));
            gaEvents.customEvent('klik_form', {
                btn_create_order: "create error new order"
            })
        }
        dispatch(submitOrderAddFormAC());
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};

export const updateProductionStatusThunk =
    (service_id, status) => (dispatch) => {
        dispatch(toggleLoaderStatusAC(true));
        apiService.updateProductionStatus(service_id, status).then((res) => {
            if (res && res.error) {
                dispatch(setAlertMessageThunk(res.error, "error"));
            }

            dispatch(toggleLoaderStatusAC(false));
            // dispatch(setProductionsTaskOrdersThunk());
        }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        });
    };
export const updateOrderProductionDateThunk =
    (order_id, date, isStatus = false, successCallback = false) => (dispatch) => {
        dispatch(toggleLoaderStatusAC(true));

        apiService.updateOrderProductionDate(order_id, date).then((res) => {
            //   console.log(res.error_api.mysql[0].error);
            if (res && res.error_api) {
                dispatch(
                    setAlertMessageThunk(res.error_api.api_error[0].error, "error")
                );
            } else {
                if (successCallback) successCallback();
            }

            let orderData = {
                limit: 1
            };
            if (isStatus) {
                orderData.status = isStatus;
            }

            //:: TODO Отправка актуальных значений (text Id, дата производства) без изменения глобального состояния
            dispatch(toggleLoaderStatusAC(false));
            // dispatch(setOrdersDataThunk(orderData));
            // dispatch(setProductionsTaskOrdersThunk());
        }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        });
    };

// Отправка актуальных значений (text Id, дата производства) без изменения состояния
export const sendActualValueProductionDateThunk =
    (order_id, date) => (dispatch) => {
        dispatch(toggleLoaderStatusAC(true));

        apiService.updateOrderProductionDate(order_id, date).then((res) => {
            if (res && res.error_api) {
                dispatch(
                    setAlertMessageThunk(res.error_api.api_error[0].error, "error")
                );
            }

            dispatch(toggleLoaderStatusAC(false));
        }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        });
    };

export const updateProductionTaskProductionDateThunk =
    (order_id, date) => (dispatch) => {
        dispatch(toggleLoaderStatusAC(true));
        apiService
            .updateProductionTaskProductionDate(order_id, date)
            .then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(toggleLoaderStatusAC(false));
                dispatch(getProductionsStatusThunk());
            }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        });
    };

export const updateOrderProductionStageThunk =
    (order_id, stage, successCallback = false) => (dispatch) => {
        dispatch(toggleLoaderStatusAC(true));
        apiService.updateOrderProductionStage(order_id, stage).then((res) => {
            if (res && res.error_api) {
                dispatch(setAlertMessageThunk(res["error_api"]["api_error"][0].error, "error"));
            } else {
                if (successCallback) successCallback();
            }
            dispatch(toggleLoaderStatusAC(false));

            //Todo:: закоментировала что-бы не изм состояния
            // dispatch(setOrdersDataThunk({limit: 1}));
            // dispatch(setProductionsTaskOrdersThunk());
        }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
        });
    };

export const changeCalcTypeIntoAC = (calc_type, id) => {
    return {
        type: CHANGE_ORDER_DETAIL_MATERIAL_CALC_TYPES,
        calc_type: calc_type,
        id: id
    };
};
export const setCheck = (list) => {
    return {
        type: SET_CHEXBOX,
        check: list
    };
};
export const selectMaterialCheck = (selected) => {
    return {
        type: TOGGLE_SELECT_BY_MATERIAL,
        check: selected
    };
};
export const onToggleOrderGroup = (payload) => {
    return {
        type: TOGGLE_SELECT_BY_MATERIAL_GROUP,
        payload
    };
};
export const resetFilter = () => {
    return {
        type: RESET_FILTERS
    };
};

export const TogggleCheck = (id) => {
    return {
        type: TOGGLE,
        id: id
    };
};
export const SetTogggleCheck = (data) => {
    return {
        type: SET_TOGGLE,
        data
    };
};

export const createProductionTaskThunk = (prodOrders, cb = false) => async (dispatch, getState) => {
    const userId = getState().auth?.user?.id;
    dispatch(toggleLoaderStatusAC(true));
    if (!prodOrders?.manager) {
        prodOrders = {
            ...prodOrders,
            manager: userId
        }
    }
    apiService.createProductionTask(prodOrders).then((res) => {
        if (res && res.error_api) {
            dispatch(setAlertMessageThunk(res.error_api.api_error[0].error, "error"));
            dispatch(toggleLoaderStatusAC(false));
            return
        }

        dispatch(OrdersFor());
        if (cb) cb()
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};

export const getProductionsStatusThunk =
    (filters = false) =>
        (dispatch) => {
            dispatch(toggleLoaderStatusAC(true));
            apiService.getProductionTaskType(filters).then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(setProductionTasksType(res ? res : []));
                dispatch(toggleLoaderStatusAC(false));
            }).catch(e => {
                dispatch(toggleLoaderStatusAC(false));
            });
        };
export const getDashboardDataThunk =
    (filters = false) =>
        (dispatch) => {
            dispatch(toggleLoaderStatusAC(true));
            apiService.getDashboardType(filters).then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                // console.log('getDashboardDataThunk', res);
                dispatch(setStatisticsDataAc(res ? res : [], 0));
                dispatch(toggleLoaderStatusAC(false));
            }).catch(e => {
                dispatch(toggleLoaderStatusAC(false));
            });
        };

export const getDashboardDataSingleThunk =
    (filters = false) =>
        (dispatch) => {
            dispatch(toggleLoaderStatusAC(true));
            apiService.getDashboardSingle(filters).then((res) => {
                if (res && res.error) {
                    dispatch(setAlertMessageThunk(res.error, "error"));
                }
                dispatch(setStatisticsSingleDataAc(res ? res : [], 0));
                dispatch(toggleLoaderStatusAC(false));
            }).catch(e => {
                dispatch(toggleLoaderStatusAC(false));
            });
        };

export const getProductionTasksFilteredDataThunk =
    (input, value, filters) => (dispatch) => {
        dispatch(changeProductionTasksFilterFormValueAC(input, value));
        filters[input] = value;
        dispatch(getProductionsStatusThunk(filters));
    };
export const getDashboardFilteredDataThunk =
    (input, value, filters) => (dispatch) => {
        dispatch(changeDashboardFilterFormValueAC(input, value));
        filters[input] = value;
        dispatch(getDashboardDataThunk(filters));
    };
export const getDashboardFilteredDataSingleThunk =
    (input, value, filters) => (dispatch) => {
        dispatch(changeDashboardFilterFormValueSingleAC(input, value));
        filters[input] = value;
        dispatch(getDashboardDataSingleThunk(filters));
    };
export const updateSettingsAC = (production_constants = "1") => {
    return {
        type: UPDATE_SETTINGS,
        production_constants: production_constants
    };
};
export const updateSettingsThunk = () => (dispatch) => {
    apiService.getSettings().then((res) => {
        if (res) {
            dispatch(updateSettingsAC(res?.[0]?.production));
        }
        if (res?.[0]?.managers?.data?.length) {
            let updateDt = res?.[0]?.managers?.data?.map(item => ({
                id: item?.user_id,
                name: item?.name,
                email: item?.email,
                phone: item?.phone
            }))
            dispatch(setDataManagersAC(updateDt));
        }
    });
};

export const changeProductionTasksFilterFormValueAC = (input, value) => {
    return {
        type: CHANGE_PRODUCTIONTASKS_FILTER_FORM_DATA,
        input: input,
        value: value
    };
};
export const changeDashboardFilterFormValueAC = (input, value) => {
    return {
        type: CHANGE_DASHBOARD_FILTER_FORM_DATA,
        input: input,
        value: value
    };
};
export const changeDashboardFilterFormValueSingleAC = (input, value) => {
    return {
        type: CHANGE_DASHBOARD_FILTER_FORM_DATA_SINGLE,
        input: input,
        value: value
    };
};

export const getProductionTasksByMaterialThunk = (id) => (dispatch) => {
    apiService.getProductionTasksByMaterials().then((res) => {
        setProductionOrderDetailDataThunk(id);
        dispatch(setProductionTasksByMaterial(res));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
};


export const onChangeStatusAutomaticallyTaskThunk = (payload, callback) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.autocompleteTask(payload.status, payload.id).then(res => {
        if (res && res.error_api) {
            dispatch(setAlertMessageThunk(res.error_api["api_error"][0].error, "error"));

        } else {
            if (callback) callback()

        }
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};

export const cacheResetThunc = (params, callback) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.cacheResetTask(params).then(res => {
        if (res && res.error_api) {
            dispatch(setAlertMessageThunk(res.error_api["api_error"][0].error, "error"));
        } else {
            if (callback) callback();
        }
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};
export const uploadOne_C_CodeThunc = (params, callback) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.uploadOne_C_Code(params).then(res => {
        if (res && res.error_api) {
            dispatch(setAlertMessageThunk(res.error_api["api_error"][0].error, "error"));
        } else {
            if (callback) callback(res["1c"].code1c);
        }
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
};


export const setMaterialGoods1CSystem = (data, successCallback) => (dispatch) => {
    dispatch(setAlertMessageThunk(getTranslateMessageAPI("Подгрузка данных с 1С"), "success"));
    apiService.giveMaterialGoods1CSystem({
        "code": "21669",
        "code1c": "00000017471",
        "code_ifp": "5hyxSt7f"
    }).then(res => {
        if (successCallback) successCallback(res)
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })

};

export const changeContragentDataThunc = (data, callback = false) => (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService.changeContragent(data).then(res => {
        if (res && res.error_api) {
            dispatch(setAlertMessageThunk(res.error_api["api_error"][0].error, "error"));
        } else {
            if (callback) callback();
        }
        dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    });
}
export const setSettings1CDataAC = (value) => {
    return {
        type: SET_SETTINGS_1C_DATA,
        orders_check_enable: value
    }
}
export const setSettingsProjectAC = (value) => {
    return {
        type: SET_SETTINGS_PROJECT_DATA,
        settings_project: value
    }
}
// getSettings1CDataThunc

export const getMainSettingsProject = (data, callback = false) => (dispatch) => {
    // dispatch(toggleLoaderStatusAC(true));
    apiService.getMainSettings(data).then(res => {
        if (res && res.error_api) {
            dispatch(setAlertMessageThunk(res.error_api["api_error"][0].error, "error"));
        } else {
            dispatch(setSettingsProjectAC(res[0].production))
            dispatch(setSettings1CDataAC(res[0].production["1c.orders_check_enable"]))
            dispatch(setPaymountStatusConst(res[0].base_data.payment_status));
            dispatch(setCurrenciesDataAC(res?.[0]?.base_data?.currency ?? []))
        }
        // dispatch(toggleLoaderStatusAC(false));
    }).catch(e => {
        // dispatch(toggleLoaderStatusAC(false));
    });
}

export const onClearActualFormSearch = (payload) => {
    return {
        type: CLEAR_ACTUAL_FORM_SEARCH,
        payload
    }
}
export const onClearActualSearchTunck = () => dispatch => {
    let payload = {
        client: "",
        code: "",
        code1c: "",
        collected: "",
        date: "",
        date_production: "",
        error1c: "",
        id: "",
        limit: 1,
        manager: "",
        material_type: "",
        materials: [],
        name: "",
        order_out_of_date: "",
        production_stage_number: "",
        service_type: "",
        services: [],
        status: "actual",
        summ_order: "",
    }
    dispatch(onClearActualFormSearch(payload))
    dispatch(setOrdersDataThunk(payload));
}
export const onClearCanceledTunck = (params) => dispatch => {

    let payload = {
        client: "",
        code: "",
        code1c: "",
        collected: "",
        date: "",
        date_production: "",
        error1c: "",
        id: "",
        limit: 1,
        manager: "",
        material_type: "",
        materials: [],
        name: "",
        order_out_of_date: "",
        production_stage_number: "",
        service_type: "",
        services: [],
        status: "actual",
        summ_order: "",
    }
    dispatch(onClearActualFormSearch(payload))
    dispatch(setOrdersDataThunk(params));
}


export const onChangeFileManagerModalDT = (payload) => {
    return {
        type: FILE_MANAGER_MODAL_DT,
        payload
    }
}
export const deleteItemFileManagerDT = (url) => async (dispatch, getState) => {
    dispatch(toggleLoaderStatusAC(true));
    const response = await apiService._deleteFilesListManager(url);
    const listFiles = getState().main.fileManager.listFiles;
    const updateResponse = listFiles.filter(l => l.link_delete != url);

    try {
        if (!response.hasOwnProperty('error_api') && response.delete == 'ok') {
            dispatch(onChangeFileManagerModalDT({listFiles: updateResponse}))
        } else if (response.hasOwnProperty('error_api') && response['error_api']['api_error']) {
            dispatch(setAlertMessageThunk(response['error_api']['api_error'][0].error, "error"));
        }
    } catch (e) {
        if (response.hasOwnProperty('error_api') && response['error_api']['api_error']) {
            dispatch(setAlertMessageThunk(response['error_api']['api_error'][0].error, "error"));
        }
        dispatch(toggleLoaderStatusAC(false));
    }
    dispatch(toggleLoaderStatusAC(false));
}

export const setFileManagerDT = ({isOpen, idProject}) => async (dispatch) => {
    try {
        const response = await apiService._getFilesListManager(idProject);
        if (!response.hasOwnProperty('error_api')) {
            // console.log({isOpen: true, listFiles: Array.isArray(response) ? response : [response]});
            dispatch(onChangeFileManagerModalDT({
                isOpen: true,
                listFiles: Array.isArray(response) ? response : [response]
            }))
        } else if (response.hasOwnProperty('error_api') && response['error_api']['api_error']) {
            dispatch(setAlertMessageThunk(response['error_api']['api_error'][0].error, "error"));
        }
    } catch (e) {
        dispatch(toggleLoaderStatusAC(false));
    }
}

export const importFileManageProjectsThunk = (files) => (dispatch, getState) => {
    const orderCode = getState().main.order_detail.code;
    // const isManager = getState().auth.user.isManager;
    dispatch(toggleLoaderStatusAC(true));
    // dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Отправляем проекты на проверку...')));
    apiService._importProject(files, 'files/', [{name: 'action', value: 'add_file'}, {
        name: 'order',
        value: orderCode
    }, {name: 'isManager', value: true}])
        .then(response => {
            if (response.hasOwnProperty('error_api') && response['error_api'].hasOwnProperty('api_error')) {
                dispatch(setAlertMessageThunk(response['error_api']['api_error'][0].error, "error"));
                dispatch(toggleLoaderStatusAC(false));
                return
            } else if (response.hasOwnProperty('res') && (response.res == 'ok')) {
                dispatch(onChangeFileManagerModalDT({listFiles: response.dir, isAddedFiles: true}))
            }
        }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
    })
    dispatch(toggleLoaderStatusAC(false));
}

export const onChangeOrderPaymountStatus = ({order_id, code}) => async dispatch => {
    dispatch(toggleLoaderStatusAC(true));
    apiService._orderChangePaymountStatus({code, order_id})
        .then(res => {
            if (res.hasOwnProperty('error_api') && res['error_api'].hasOwnProperty('api_error')) {
                dispatch(setAlertMessageThunk(res['error_api']['api_error'][0].error, "error"));
                dispatch(toggleLoaderStatusAC(false));
            } else {

            }
            dispatch(toggleLoaderStatusAC(false));
        }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
        dispatch(setAlertMessageThunk(e.message, "error"));
    })
}


export const onDeleteOrderProject = ({id, code, v}) => async (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    apiService._deleteOrder({v, code})
        .then(response => {
            if (response.hasOwnProperty('error_api') && response['error_api'].hasOwnProperty('api_error')) {
                dispatch(setAlertMessageThunk(response['error_api']['api_error'][0].error, "error"));
                dispatch(toggleLoaderStatusAC(false));
            } else if (response.res === 'ok' || response?.[id]) {
                dispatch(deleteOrderId(id));
                if (response?.data?.[id] === 'no_details' || response?.[id] === 'no_details') {
                    dispatch(setAlertMessageThunk(`${getTranslateMessageAPI('В это заказе не было деталей!')} ${getTranslateMessageAPI('Заказ успешно удален!')}`));
                }
                dispatch(setAlertMessageThunk(getTranslateMessageAPI('Заказ успешно удален!')));
                dispatch(toggleLoaderStatusAC(false))
            }
            dispatch(toggleLoaderStatusAC(false))
        }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));
        dispatch(setAlertMessageThunk(e.message, "error"));
    })
}

export const fetchingProjectProductionAllMaterials = () => async (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    try {
        const materials = await apiService._getAllProjectOrderMaterials();
        if (materials.hasOwnProperty('error_api') && materials['error_api'].hasOwnProperty('api_error')) {
            dispatch(setAlertMessageThunk(materials['error_api']['api_error'][0].error, "error"));
            // dispatch(toggleLoaderStatusAC(false));
        }
        if (materials) {
            dispatch(ACProjectProductionAllMaterials(materials))
        }

    } catch (e) {
        dispatch(setAlertMessageThunk(e.message, "error"));
    } finally {
        dispatch(toggleLoaderStatusAC(false));
    }
}
export const fetchingProjectProductionByIdMaterials = ({id}) => async (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));
    try {
        const materials = await apiService._getByIdProjectOrderMaterial({id});
        if (materials.hasOwnProperty('error_api') && materials['error_api'].hasOwnProperty('api_error')) {
            dispatch(setAlertMessageThunk(materials['error_api']['api_error'][0].error, "error"));
            dispatch(toggleLoaderStatusAC(false));
            return
        }
        if (materials) {
            dispatch(ACProjectProductionSetMaterialOrders(materials))
        }

    } catch (e) {
        dispatch(setAlertMessageThunk(e.message, "error"));
    } finally {
        dispatch(toggleLoaderStatusAC(false));
    }
}

export const fetchingUpdateCommentOfDetail = ({orderId, comment, cb = false}) => async (dispatch) => {
    dispatch(toggleLoaderStatusAC(true));

    try {
        const response = await apiService._updateCommentOfOrder({orderId, comment});
        if (response.hasOwnProperty('error_api') && response['error_api'].hasOwnProperty('api_error')) {
            dispatch(setAlertMessageThunk(response['error_api']['api_error'][0].error, "error"));
            dispatch(toggleLoaderStatusAC(false));
            return;
        }

        if (response.comment) {

            dispatch(ACUpdateCommentOfOrder(response.comment));
            if (cb) cb()
        }
    } catch (e) {

    } finally {
        dispatch(toggleLoaderStatusAC(false));

    }
}

export const packagingPrintingHtml = ({
                                          settings_for_print = PACKAGING_PRINTING_PRINT_FORM_DEF_VALUES,
                                          projectOrder
                                      }) => async (dispatch, getState) => {
    const state = getState();
    const production_vat_display_short = state?.main?.settings_project?.['production.vat_added_tax_show_needed'] || 1
    const production_vat = state?.main?.settings_project?.['production.VAT_add_price_if_more_than_0'] || 0
    const lang = localStorage.getItem('lang');
    const order = projectOrder.project_data;
    const manager = state.auth.user?.name ?? '-';
    const currencies = state.main?.currency || [];
    const currency = currencies?.find(item => Number(item.currency_id) === Number(order?.currency))?.name?.[lang] ?? '';
    const client_name = order?.client_name || '-';
    const packagingHtml = new PackagingHtml();
    const unit = 'кг'
    dispatch(toggleLoaderStatusAC(true));
    try {
        const content = packagingHtml.generateContentHtml({
            order: order, setting: {...settings_for_print}, unit, currency, manager_name: manager,
            production_vat: Number(production_vat),
            production_vat_display_short: !Number(production_vat_display_short) && !!Number(production_vat)
        });
        packagingWindowHtml.openWindowPrint({content, order})
    } catch (e) {
        console.log(e);
        // checkErrorsAPI("", `Packaging Print -> create html [message error ${e.message}]`);
    }
    dispatch(toggleLoaderStatusAC(false));
}

export const updateDataCuttingForHtml = (payload, projectOrder) => async (dispatch, getState) => {
    const order = projectOrder.project_data;
    const cuttingCard = projectOrder.project_data?.cutting_card;
    let projectDetail = projectOrder.project_data;
    const cuttingHtml = new CuttingHtml()

    dispatch(toggleLoaderStatusAC(true));
    try {
        const previewImages = await apiService._getPartsSmallPreviews(order);
        if (previewImages) {
            projectDetail = {
                ...projectDetail, partsPreview: previewImages
            }
        }
        const cards = cuttingCard?.cards.map(card => {
            const findCard = payload.cards.find(dto => Number(dto.card_id) === Number(card.card_id));
            return {
                ...card, visible_xnc: !!(findCard?.parts_info ?? false), visible_card: !!(findCard?.card ?? false)
            };
        });
        let cutting_card = {
            ...cuttingCard, cards: cards
        };

        const content = cuttingHtml.generateContentHtml({
            order, setting: {
                cutting_card,
                general: payload.general,
                xnc: payload.xnc,
                mode: payload.mode,
                modeDetailing: payload.modeDetailing
            }, projectDetail
        });

        cuttingWindowHtml.openWindowPrint({content, order});
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        console.log(e);
        // checkErrorsAPI("", `Cutting card -> create html [message error ${e.message}]`);
    } finally {
        dispatch(toggleLoaderStatusAC(false));
    }
}


export const LABELS_CUTTING_PRINT = {
    giblab: "giblab",
    ifurn: "ifurn"
};
export const TYPES_BASE64_LABELS = {
    png: "data:image/png;base64,", svg: "data:image/svg+xml;base64,"
};
export const getCuttingLabelsForPrint = ({
                                             action, project = false, grid = ''
                                         }) => (dispatch, getState) => {

    let order_detail = getState().main.order_detail.project_data;
    let order = project ?? order_detail;
    dispatch(toggleLoaderStatusAC(true));
    try {
        switch (action) {
            case LABELS_CUTTING_PRINT.ifurn: {
                apiService._fetchIfurnLables(order?.id, "all")
                    .then(imageObjectURL => {
                        const labels = JSON.parse(imageObjectURL);
                        // if(typeof imageObjectURL  === 'string'){
                        //     dispatch(setAlertMessageThunk(imageObjectURL, "error"));
                        //     dispatch(toggleLoaderStatusAC(false));
                        //     return;
                        // }

                        if (Array.isArray(labels) && !labels?.length) {
                            dispatch(setAlertMessageThunk(getTranslateMessageAPI('Этикеток нет в проекте')));
                            dispatch(toggleLoaderStatusAC(false));
                            return;
                            // dispatch(checkErrorsAPI("", `${apiGb._errors}`));
                        }

                        if (imageObjectURL) {
                            let content = Labels({
                                src: JSON.parse(imageObjectURL),
                                type: TYPES_BASE64_LABELS.svg,
                                grid: grid
                            });
                            labelsWindowHtml.openWindowPrint({content, order});
                        } else if (imageObjectURL.hasOwnProperty('error_api')) {
                            dispatch(setAlertMessageThunk(imageObjectURL?.['error_api']?.['api_error']?.[0]?.error, "error"));
                            // dispatch(checkErrorsAPI("", `${apiGb._errors}`));
                        }

                        dispatch(toggleLoaderStatusAC(false));

                    }).catch(e => {
                    return e;
                });
                break;
            }
            case LABELS_CUTTING_PRINT.giblab: {
                order = {
                    ...order,
                    all: 1
                }
                apiService._fetchGiblabLabels(order)
                    .then(imageObjectURL => {
                        if (Array.isArray(imageObjectURL) && !imageObjectURL?.length) {
                            dispatch(setAlertMessageThunk(getTranslateMessageAPI('Этикеток нет в проекте')));
                            dispatch(toggleLoaderStatusAC(false));
                            return;
                            // dispatch(checkErrorsAPI("", `${apiGb._errors}`));
                        }

                        // if(Array.isArray(labels))
                        if (imageObjectURL) {
                            let content = Labels({src: imageObjectURL, type: TYPES_BASE64_LABELS.png, grid: grid});
                            labelsWindowHtml.openWindowPrint({content, order});
                        } else if (imageObjectURL.hasOwnProperty('error_api')) {
                            dispatch(setAlertMessageThunk(imageObjectURL?.['error_api']?.['api_error']?.[0]?.error, "error"));
                            // dispatch(checkErrorsAPI("", `${apiGb._errors}`));
                        }
                        dispatch(toggleLoaderStatusAC(false));

                    })
                    .catch(e => {
                        return e;
                    });
                break;
            }
            default: {
                console.info("action not found", action);
                dispatch(toggleLoaderStatusAC(false));

            }
        }

    } catch (e) {
        dispatch(setAlertMessageThunk(`Generate html for labels, [message: ${e.message}]`, "error"));
        dispatch(toggleLoaderStatusAC(false));
    }

};

export const getGiblabDataThunk = (type, order) => (dispatch, getState) => {
    dispatch(toggleLoaderStatusAC(true));
    const apiGiblab = new ApiGiblab();

    try {
        apiGiblab._getXml(order.project_data)
            .then(({xml, red_res}) => {
                if (apiGiblab._errors) {
                    dispatch(setAlertMessageThunk(`${apiGiblab._getErrors()}`, "error"));
                    dispatch(toggleLoaderStatusAC(false));
                    return false;
                }
                if (!xml) return false;
                if (xml === "no_cs") {
                } else {
                    if (red_res !== null) {
                        // dispatch(gitlabMiscalculationCaching({ type, result: red_res, order }));
                        return;
                    }
                    // domain

                    apiGiblab._checkApiDomain()
                        .then((domain) => {
                            if (!domain) {
                                dispatch(toggleLoaderStatusAC(false));
                                return false
                            }
                            ;
                            apiGiblab._sendGiblab(domain, xml)
                                .then(async (result) => {
                                    if (apiGiblab._errors) {
                                        dispatch(setAlertMessageThunk(`${apiGiblab._getErrors()}`, "error"));
                                        dispatch(toggleLoaderStatusAC(false));
                                    }

                                    let regexp = /[а-яё]/i;
                                    // проверка на завершение запроса
                                    if (result.timout || regexp.test(result)) {
                                        try {
                                            // dispatch(changeLoaderMessageAC("Дополнительная калькуляция проекта..."));
                                            const result = await apiGiblab._Service(xml);
                                            if (!regexp.test(result)) {
                                                apiService.getPDF(type, order, result).catch(e => {
                                                    dispatch(toggleLoaderStatusAC(false));
                                                    dispatch(setAlertMessageThunk(`getPDF, [message: ${e.message}]`, "error"));
                                                });
                                                return;
                                            }
                                            throw {
                                                message: result
                                            }
                                        } catch (e) {
                                            //   after cansel request failed send additional service
                                            dispatch(setAlertMessageThunk(`_checkApiDomain, [message: ${e.message}]`, "error"));
                                        }
                                    } else {
                                        apiService.getPDF(type, order, result).catch(e => {
                                            dispatch(toggleLoaderStatusAC(false));
                                            dispatch(setAlertMessageThunk(`getPDF, [message: ${e.message}]`, "error"));
                                        });
                                    }
                                    if (!result) return false;


                                }).catch(e => {
                                dispatch(toggleLoaderStatusAC(false));
                                dispatch(setAlertMessageThunk(`_checkApiDomain, [message: ${e.message}]`, "error"));
                            });
                        }).catch(async e => {
                        try {
                            // dispatch(changeLoaderMessageAC("Дополнительная калькуляция проекта..."));
                            let regexp = /[а-яё]/i;
                            const result = await apiGiblab._Service(xml);
                            if (!regexp.test(result)) {
                                apiService.getPDF(type, order, result).catch(e => {
                                    dispatch(toggleLoaderStatusAC(false));
                                    dispatch(setAlertMessageThunk(`getPDF, [message: ${e.message}]`, "error"));
                                });
                                return;
                            }
                            throw {
                                message: result
                            }
                        } catch (e) {
                            dispatch(toggleLoaderStatusAC(false));
                            dispatch(setAlertMessageThunk(`getGiblabDataThunk, [message: ${e.message}]`, "error"));
                            //   after cansel request failed send additional service
                            dispatch(setAlertMessageThunk(`_checkApiDomain, [message: ${e.message}]`, "error"));
                        }

                    });

                }

            }).catch(e => {
            dispatch(toggleLoaderStatusAC(false));
            dispatch(setAlertMessageThunk(`_getXml, [message: ${e.message}]`, "error"));

        });

    } catch (e) {
        dispatch(setAlertMessageThunk(`getGiblabDataThunk, [message: ${e.message}]`, "error"));
        // dispatch(checkErrorsAPI(typeErrorApi.gb_letter));
        console.log(e);
    } finally {
        // dispatch(toggleLoaderStatusAC(false)) ;
    }

};

export const initialOrderSearch = (cbHideLoader) => (dispatch, getState) => {

    dispatch(toggleLoaderStatusAC(true));
    const filterForm = getState().main.ordersForm
    dispatch(setOrdersDataThunk(
        {
            ...filterForm, status: filterForm.status === "actual" ? '' : filterForm?.status
        }
    ));
    dispatch(setClientsDataThunk(false));
    dispatch(setMaterialsThunk(false))
    dispatch(setServiceThunk(false))
    dispatch(updateSettingsThunk())
    dispatch(changeOrdersFilterFormValueAC("status", filterForm.status === "actual" ? '' : filterForm?.status))

    dispatch(toggleLoaderStatusAC(false));
    if (cbHideLoader) cbHideLoader();
}
export const setNewsAC = (payload) => {
    return {
        type: NEWS_SET_LIST,
        payload: payload
    }
}
export const getNewsThunk = (page = 1) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response_news = await apiService._getNews(page);
        if (response_news.hasOwnProperty('error')) {
            dispatch(setAlertMessageThunk(response_news.error));
            return
        }
        dispatch(setNewsAC(response_news));
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(toggleLoaderStatusAC(false));
        dispatch(setNewsAC(NEWS_INITIAL_VALUES));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(setAlertMessageThunk(msg));
    }
}


export default AccountReducer;
