import panel from "./panel";
import getT from "./getT";
import {cutting_style} from "./styles/cutting_style";
import {packaging_style} from "./styles/packaging_style";
import {short_order_style} from "./styles/short_order_style";
import {merge_style} from "./styles/merge_style";

const ACTION_TYPE_STYLE = {
    blank: "blank",
    cutting: "cutting",
    labels: 'labels',
    merge: 'merge',
    packaging: 'packaging',
    short_order_form: "short_order_form",
    report_paz: "report_paz"
};

class PrintHtml {
    constructor(style) {
        this.stylePage = style;
    }

    _b64DecodeUnicode(str) {
        return decodeURIComponent(atob(str).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
    }

    _utf8_to_b64(str) {
        return btoa(unescape(encodeURIComponent(str)));
    }

    renderLinkStyle() {
        switch (this.stylePage) {
            case ACTION_TYPE_STYLE.blank: {
                return `  
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/css/blank.mobile.css"/>
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/js/bootstrap/bootstrap.min.css"/>
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/css/index.css"/>
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/css/media.css"/>
          <link type="text/css" rel="stylesheet"/>`;
            }
            case ACTION_TYPE_STYLE.packaging: {
                return `
           <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/js/bootstrap/bootstrap.min.css"/>
       ${packaging_style}`
            }
            case ACTION_TYPE_STYLE.cutting: {
                return `
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/js/bootstrap/bootstrap.min.css"/>
             ${cutting_style}
                              <style>
                 @media print {
                 .cutting-view-wrap .cutting-main-view:first-child .cutting-chat-view {
                    /*zoom: 0.85;*/
                }
  /*body {*/
  /*  transform: scale(0.9); !* Уменьшение масштаба до 80% *!*/
  /*  transform-origin: top left; !* Определение точки начала преобразования *!*/
  /*}*/
}
</style>
        `
            }
            case ACTION_TYPE_STYLE.labels: {
                return `
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/js/bootstrap/bootstrap.min.css"/>
          <style type="text/css">
          .item{
          text-align: center;
          border: 1px solid #000000;
          }
              img{
              max-width: 100%;
              }
        </style>
        `
            }
            case ACTION_TYPE_STYLE.merge: {
                return `  
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/css/blank.mobile.css"/>
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/js/bootstrap/bootstrap.min.css"/>
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/css/index.css"/>
          <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/css/media.css"/>
          <link type="text/css" rel="stylesheet"/>
           ${merge_style}
`;
            }
            case ACTION_TYPE_STYLE.report_paz: {
                return `
                     <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/js/bootstrap/bootstrap.min.css"/>
                  
          `
            }
            case  ACTION_TYPE_STYLE.short_order_form: {
                return `
                  <link type="text/css" rel="stylesheet" href="https://q.ifurn.pro/js/bootstrap/bootstrap.min.css"/>
                 ${short_order_style}
                `
            }
            default: {
                return "";
            }
        }

    }

    renderBase64ToHtmlWindow(base64, title = "iFurn.pro", order) {
        const content = this._b64DecodeUnicode(base64);
        this.openWindowPrint({content, title, order});
    }

    renderHtmlWindow(html, title = "iFurn.pro", order) {
        this.openWindowPrint({content: html, order, title});
    }

    subjectTitlePanel() {
        switch (this.stylePage) {
            case ACTION_TYPE_STYLE.labels: {
                return getT('Этикетки')
            }
            case ACTION_TYPE_STYLE.cutting: {
                return getT('Карта кроя')
            }
            case ACTION_TYPE_STYLE.blank: {
                return getT('Бланк')
            }
            case ACTION_TYPE_STYLE.packaging: {
                return getT('Упаковка')
            }
            default: {
                return ''
            }
        }
    }

    openWindowPrint({content, title = "iFurn.pro", order}) {
        let subjectTitle = `${order?.id} ${this.subjectTitlePanel()}`
        let printWindow = window.open("", "_blank");
        printWindow.document.write(`
    <html>
      <head>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          ${this.renderLinkStyle()}
          <style>
          @media print {
          .panel-print{
          display: none;          }
          }
          .cutting-main-view{
          padding-bottom: 0px;
          }
           </style>
          <title>${title} ${!!order?.id && `№ ${order?.id}`}</title>
        <head>
        <body>
        ${panel({order, subjectTitle})}
    `);

        printWindow.document.write(content);
        printWindow.document.write(`</body></html>`);
        printWindow.document.close();
    }

}

export const printHtml = new PrintHtml(ACTION_TYPE_STYLE.blank);
export const cuttingWindowHtml = new PrintHtml(ACTION_TYPE_STYLE.cutting);
export const labelsWindowHtml = new PrintHtml(ACTION_TYPE_STYLE.labels);

export const mergeWindowHtml = new PrintHtml(ACTION_TYPE_STYLE.merge);

export const packagingWindowHtml = new PrintHtml(ACTION_TYPE_STYLE.packaging);

export const shortOrderFormWindowHtml = new PrintHtml(ACTION_TYPE_STYLE.short_order_form);
export const salaryShortOrderFormWindowHtml = new PrintHtml(ACTION_TYPE_STYLE.short_order_form);
export const reportPazHtml = new PrintHtml(ACTION_TYPE_STYLE.report_paz);


